import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import { MdOutlineViewHeadline } from "react-icons/md";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import store from "../../redux/store/store";
import { FaCheck, FaTimes } from "react-icons/fa";

const SendMessage = () => {
  const navigate = useNavigate();
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setviewData] = useState("");
  const [sendMessages, setSendMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const loginUserDetail = store.getState().login.auth;
  const companyId = loginUserDetail?.companyId;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSendMessageList();
  }, [page, pageSize, search]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.sendMessageId,
      // sortable: true,
    },
    {
      name: "Template Name",
      selector: (row) => (row.templateName ? row.templateName : "-"),
      // sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : "-"),
      // sortable: true,
    },
    {
      name: "Is Template",
      selector: (row) => (row.isTemplate ? <FaCheck /> : <FaTimes />),
      // sortable: true,
    },
    {
      name: "Message Text",
      selector: (row) =>
        row.messageDescription ? row.messageDescription : "-",
      // sortable: true,
    },
    {
      name: "Total Contact",
      selector: (row) => (row.totalContact ? row.totalContact : "-"),
      // sortable: true,
    },
    {
      name: "Total Sent",
      selector: (row) => (row.sentCount ? row.sentCount : "0"),
      // sortable: true,
    },
    {
      name: "Total Delivered",
      selector: (row) => (row.deliveredCount ? row.deliveredCount : "0"),
      // sortable: true,
    },
    {
      name: "Total Read",
      selector: (row) => (row.readCount ? row.readCount : "0"),
      // sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        if (row.createdAt) {
          const date = new Date(row.createdAt);
          return `${date.toLocaleDateString()}  ${date.toLocaleTimeString()}`;
        } else {
          return "-";
        }
      },

      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.id}>
          <div className="edit-btn me-2">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltips}
            >
              <button
                className="btn btn-none border-0"
                onClick={() =>
                  navigate(
                    `/sendmessage/sendMessageDetails/${d?.sendMessageId}`
                  )
                }
              >
                <MdOutlineViewHeadline className="text-black" />
              </button>
            </OverlayTrigger>
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewSendMessage(d?.sendMessageId)} />
          </div>
        </div>,
      ],
    },
  ];

  /*-------------------- API CALL START HERE --------------------*/
  const getSendMessageList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.SendMessage +
          `/company/${companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false)
      setTotalPages(pageCount);
      setSendMessages(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const viewSendMessage = async (sendMessageId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.SendMessage + `/${sendMessageId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const renderTooltips = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Sendmessage Details
    </Tooltip>
  );

  const handleCloseview = () => {
    setViewModal(false);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Send Message</h2>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={sendMessages}
            pagination={true}
            defaultSortField="sendMessageId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            progressPending={loading}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>Template Name :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.templateName}</p>
                </div>
                <div className="col-md-5">
                  <p>Description :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.description || "-"}</p>
                </div>
                <div className="col-md-5">
                  <p>Is Template :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.isTemplate ? <FaCheck /> : <FaTimes />}</p>
                </div>
                <div className="col-md-5">
                  <p>Message Text :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.messageDescription || "-"}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Contact :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.totalContact}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Sent :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.sentCount}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Delivered :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.deliveredCount}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Read :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.readCount}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SendMessage;
