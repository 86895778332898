import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import { FaCheck, FaTimes } from "react-icons/fa";

const SendMessageDetails = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setviewData] = useState("");
  const [sendMessageDetails, setSendMessageDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const { id } = useParams();

  useEffect(() => {
    getSendMessageDetailsList();
  }, [page, pageSize, search]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.sendMessageDetailId,
      // sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => (row.mobileNo ? row.mobileNo : "-"),
      // sortable: true,
    },
    {
      name: "Sent",
      selector: (row) => (row.sent ? <FaCheck /> : <FaTimes />),
      // sortable: true,
    },
    {
      name: "Delivered",
      selector: (row) => (row.delivered ? <FaCheck /> : <FaTimes />),
      // sortable: true,
    },
    {
      name: "Read",
      selector: (row) => (row.read ? <FaCheck /> : <FaTimes />),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.sendMessageDetailId}>
          <div className="view-btn me-2">
            <GrFormView
              onClick={() => viewSendMessage(d?.sendMessageDetailId)}
            />
          </div>
        </div>,
      ],
    },
  ];

    /*-------------------- API CALL START HERE --------------------*/
    const getSendMessageDetailsList = async () => {
      try {
        const response = await CommonApiServices.getData(
          Constants.SendMessageDetail +
            `/sendMessage/${id}?page=${page}&pageSize=${pageSize}&search=${search}`
        );
        const pageCount = response?.count;
        setTotalPages(pageCount);
        setSendMessageDetails(response?.data);
      } catch (error) {
        console.error(error.message);
      }
    };
  
  const viewSendMessage = async (sendMessageDetailId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.SendMessageDetail + `/${sendMessageDetailId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
     /*-------------------- API CALL END HERE --------------------*/

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleCloseview = () => {
    setViewModal(false);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Send Message Details</h2>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={sendMessageDetails}
            pagination={true}
            defaultSortField="sendMessageDetailId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>Mobile No. :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.mobileNo}</p>
                </div>
                <div className="col-md-4">
                  <p>Sent :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.sent ? <FaCheck /> : <FaTimes />}</p>
                </div>
                <div className="col-md-4">
                  <p>Delivered :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.delivered ? <FaCheck /> : <FaTimes />}</p>
                </div>
                <div className="col-md-4">
                  <p>Read :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.read ? <FaCheck /> : <FaTimes />}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SendMessageDetails;
