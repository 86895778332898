import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";

const OrderDetails = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setviewData] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrderDetailsList();
  }, [page, pageSize, search]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.orderDetailId,
      // sortable: true,
    },
    {
      name: "Product Retailer Id",
      selector: (row) => (row.productRetailerId ? row.productRetailerId : "-"),
      // sortable: true,
    },
    {
      name: "Price",
      selector: (row) => (row.price ? row.price : "-"),
      // sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => (row.quantity ? row.quantity : "-"),
      // sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => (row.totalAmount ? row.totalAmount : "-"),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.orderDetailId}>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewOrderDetail(d?.orderDetailId)} />
          </div>
        </div>,
      ],
    },
  ];
  /*-------------------- API CALL START HERE --------------------*/
  const getOrderDetailsList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.OrderDetail +
          `/orders/${id}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false)
      setTotalPages(pageCount);
      setOrderDetails(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const viewOrderDetail = async (orderDetailId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.OrderDetail + `/${orderDetailId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleCloseview = () => {
    setViewModal(false);
  };
 
  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Order Details</h2>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={orderDetails}
            pagination={true}
            defaultSortField="orderDetailId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            progressPending={loading}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>Product Retailer Id :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.productRetailerId}</p>
                </div>
                <div className="col-md-5">
                  <p>Price :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.price}</p>
                </div>
                <div className="col-md-5">
                  <p>Quantity :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.quantity}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Amount :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.totalAmount}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OrderDetails;
