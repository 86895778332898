import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../components/model/deleteModel";
import { Form, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import { FaCheck, FaCloudUploadAlt, FaTimes } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import Select from "react-select";
import { FaPlusCircle } from "react-icons/fa";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const MessageTemplate = () => {
  const companyId = useSelector((state) => state?.login?.auth?.companyId);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [header, setHeader] = useState("");
  const [headerCount, setHeaderCount] = useState("");
  const [body, setBody] = useState("");
  const [bodyCount, setBodyCount] = useState("");
  const [footer, setFooter] = useState("");
  const [footerCount, setFooterCount] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("");
  const [productimg, setProductimg] = useState("");
  const [product, setProduct] = useState("");
  const [allContacts, setAllContacts] = useState(false);
  const [viewData, setviewData] = useState();
  const [deleteId, setdeleteId] = useState("");
  const [id, setId] = useState();
  const [message, setMessage] = useState("");
  const [contactgroup, setContactgroup] = useState("");
  const [errors, setErrors] = useState({});
  const spaceInput = /^\s*$/;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [categoryname, setCategoryname] = useState("");
  const [additionalInputs, setAdditionalInputs] = useState([
    { categoryname: "", product: [] },
  ]);
  const [contacts, setContacts] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [sendheader, setSendHeader] = useState([]);
  const [sendheaderCount, setSendHeaderCount] = useState("");
  const [sendbody, setSendBody] = useState([]);
  const [sendbodyCount, setSendBodyCount] = useState("");
  const [sendfooter, setSendFooter] = useState([]);
  const [sendfooterCount, setSendFooterCount] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isTamplate, setIsTamplate] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [pageAll, setPageAll] = useState(1);
  const [pageSizeAll, setPageSizeAll] = useState(10);
  const [totalPagesAll, setTotalPagesAll] = useState();
  const [contactAllGroup, setContactAllGroup] = useState([]);
  const [contactAll, setContactAll] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [productImgList, setProductImgList] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loadingMessage, setLoadingMessage] = React.useState(true);

  useEffect(() => {
    getMessageTemplateList();
  }, [page, pageSize, search]);

  useEffect(() => {
    getContactAllData();
  }, [pageAll, pageSizeAll, searchText]);

  const clearData = () => {
    setBody("");
    setName("");
    setDescription("");
    setHeader("");
    setSubmitLoading(false);
    setHeaderCount("");
    setBodyCount("");
    setFooter("");
    setFooterCount("");
    setCategory("");
    setLanguage("");
    setIsTamplate(false);
    setId("");
  };

  const clearSendData = () => {
    setId("");
    setSearchText("");
    setProductimg("");
    setAllContacts(false);
    setSendLoading(false);
    setCategoryname("");
    setProduct("");
    setAdditionalInputs([{ categoryname: "", product: [] }]);
    setMessage("");
    setSendHeader([]);
    setSendBody([]);
    setSendFooter([]);
    setSendHeaderCount("");
    setSendBodyCount("");
    setSendFooterCount("");
    setSendModal(false);
    setIsTamplate(false);
    setContactgroup("");
    setContacts([]);
    setErrors({});
    setSelectedRows([]);
    setTotalPagesAll();
  };

  /*-------------------- API CALL START HERE --------------------*/
  const getMessageTemplateList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.MessageTemplate +
          `/company/${companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;

      setTotalPages(pageCount);
      setLoadingMessage(false);
      setMessageTemplates(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getProductImageList = async (id) => {
    try {
      const response = await CommonApiServices.getData(
        Constants.TemplateProduct + `/${id}/products/all/company/${companyId}`
      );
      setProductImgList(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getAllConatctGroup = async (id) => {
    try {
      const response = await CommonApiServices.getData(
        Constants.ContactGroup + `/all/company/${companyId}`
      );
      setContactAllGroup(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getContactAllData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.Contact}/company/${companyId}?page=${pageAll}&pageSize=${pageSizeAll}&search=${searchText}`
      );

      setContactAll(response.data);
      setLoading(false);
      setTotalPagesAll(response?.count);
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      name,
      description,
      ...(isTamplate === true
        ? { header, footer, body, language, category }
        : {}),
    });
    setErrors(validationErrors);
    const params = {
      companyId: companyId,
      description: description,
      templateName: name,
      isTemplate: isTamplate,
      ...(isTamplate && {
        footer: footer,
        footerCount: footerCount,
        header: header,
        body: body,
        bodyCount: bodyCount,
        category: category,
        language: language,
      }),
    };
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.MessageTemplate,
            params
          );
          clearData();
          getMessageTemplateList();
          setSubmitLoading(false);
          setId("");
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            `  ${Constants.MessageTemplate}/${id}`,
            params
          );
          clearData();
          getMessageTemplateList();
          setShow(false);
          setSubmitLoading(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }
      setErrors({});
    }
  };

  const deleteMessageTemplate = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        `  ${Constants.MessageTemplate}/${deleteId}`
      );
      getMessageTemplateList();
      setdeleteId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };
  const viewMessageTemplate = async (viewId) => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.MessageTemplate}/${viewId}`
      );
      setviewData(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const allData = [
      ...sendheader.map((input) => ({ ...input, type: "header" })),
      ...sendbody.map((input) => ({ ...input, type: "body" })),
      ...sendfooter.map((input) => ({ ...input, type: "footer" })),
    ];

    const transformedData = additionalInputs?.map((data) => {
      const products = data?.product?.map((val) => {
        return { product_retailer_id: val.value };
      });
      let result = {
        title: data.categoryname,
        product_items: products,
      };
      return result;
    });

    const newData = {
      type: "button",
      sub_type: "mpm",
      index: 0,
      parameters: [
        {
          type: "action",
          action: {
            thumbnail_product_retailer_id: productimg?.value,
            sections: transformedData,
          },
        },
      ],
    };

    // Filter out empty inputs and map them to the desired format
    const consolidatedData = allData
      .filter((input) => input.text.trim() !== "")
      .reduce((acc, input) => {
        const existingTypeIndex = acc.findIndex(
          (item) => item.type === input.type
        );
        if (existingTypeIndex !== -1) {
          acc[existingTypeIndex].parameters.push({
            type: "text",
            text: input.text,
          });
        } else {
          acc.push({
            type: input.type,
            parameters: [{ type: "text", text: input.text }],
          });
        }
        return acc;
      }, []);
    const params = {
      messageTemplateId: id,
      ...(isTamplate === true
        ? { components: [...consolidatedData, newData] }
        : { messageDescription: message }),
    };
    if (contacts?.length) {
      params["contacts"] = contacts;
    } else if (allContacts === true) {
      params["allContacts"] = allContacts;
    } else if (contactgroup) {
      params["contactGroupMasterId"] = contactgroup?.value;
    }
    const validationErrors = Validation({
      message,
      contactgroup: contactgroup || allContacts || contacts,
      ...(isTamplate === true
        ? {
            ...(sendheaderCount && sendheader && sendheader.length > 0
              ? { sendheader: sendheader[0].text } // Include the text of the first item in sendheader
              : {}),
            ...(sendbodyCount && sendbody && sendbody.length > 0
              ? { sendbody: sendbody[0].text } // Include the text of the first item in sendbody
              : {}),
            ...(sendfooterCount && sendfooter && sendfooter.length > 0
              ? { sendfooter: sendfooter[0].text } // Include the text of the first item in sendfooter
              : {}),
            categoryname,
            product,
            productimg,
          }
        : {}),
    });
    setErrors(validationErrors);
    // validateAndSetErrors()
    if (Object.keys(validationErrors).length === 0) {
      setSendLoading(true);
      try {
        const response = await CommonApiServices.authPostData(
          Constants.SendMessage + `/company/${companyId}`,
          params
        );
        clearSendData();
        getMessageTemplateList();
        setId("");
        setSendLoading(false);
        setSendModal(false);
        swal(`${response.message}`, "", "success");
        return response;
      } catch (error) {
        clearSendData();
        setSendLoading(false);
        console.error(error.message);
      }
    }
  };

  /*-------------------- API CALL END HERE --------------------*/

  // get message templatelist
  const columnss = [
    {
      name: "No",
      selector: (row) => row.messageTemplateId,
      // sortable: true,
    },
    {
      name: "TemplateName",
      selector: (row) => (row.templateName ? row.templateName : "-"),
      // sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : "-"),
      // sortable: true,
    },
    {
      name: "Header",
      selector: (row) => (row.header ? row.header : "-"),
      // sortable: true,
    },
    {
      name: "Body",
      selector: (row) => (row.body ? row.body : "-"),
      // sortable: true,
    },
    {
      name: "Footer",
      selector: (row) => (row.footer ? row.footer : "-"),
      // sortable: true,
    },
    {
      name: "Category",
      selector: (row) => (row.category ? row.category : "-"),
      // sortable: true,
    },
    {
      name: "Language",
      selector: (row) => (row.language ? row.language : "-"),
      // sortable: true,
    },
    {
      name: "Is Template",
      // cell: (d) => (d.isTemplate === true ? "Yes" : "No"),
      selector: (row) => (row.isTemplate ? <FaCheck /> : <FaTimes />),
      // selector: (row) => (row.isTemplate ? row.isTemplate : "-"),
      // sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   cell: (d) => [
    //     <Button className="table-green-label" size="sm">
    //       Active
    //     </Button>,
    //   ],
    // },
    {
      name: "Action",
      sortable: false,
      width: "300px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.messageTemplateId}>
          <div className="edit-btn">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView
              onClick={() => {
                setViewModal(true);
                setIsTamplate(d?.isTemplate);
                viewMessageTemplate(d?.messageTemplateId);
              }}
            />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setdeleteId(d?.messageTemplateId);
                setDeleteModal(true);
              }}
            />
          </div>
          <div className="edit-btn me-2">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltips}
            >
              <button
                className="btn btn-none border-0"
                onClick={() => modalHandleforSend(d)}
              >
                <IoIosSend className="text-black" />
              </button>
            </OverlayTrigger>
          </div>
          {d?.isTemplate && (
            <div className="view-btn me-2">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  className="btn btn-none border-0"
                  onClick={() =>
                    navigate(
                      `/messagetemplate/producttemplate/${d?.messageTemplateId}`
                    )
                  }
                >
                  <FaCloudUploadAlt />
                </button>
              </OverlayTrigger>
            </div>
          )}
        </div>,
      ],
    },
  ];

  // send column and row data
  const subColumn = [
    {
      width: 250,
      headerName: "Name",
      field: "name",
    },
    {
      width: 250,
      headerName: "Mobile No",
      field: "mobileNo",
      // sortable: true,
    },
  ];

  //Send messsage product id options
  const productImgOptions =
    productImgList.map((item) => ({
      value: item?.productRetailerId,
      label: item?.imageLink ? (
        <>
          {item.title} - {item?.productRetailerId} -{" "}
          <img
            src={item.imageLink}
            alt={item.title}
            style={{ width: "30px" }}
          />{" "}
        </>
      ) : (
        `${item.title} - ${item?.productRetailerId} - No Image URL`
      ),
    })) || [];

  //Send messsage product id options
  const contactGroupOptions = [
    { value: undefined, label: "Select a contact group" },
    ...(contactAllGroup?.map((item) => ({
      value: item?.contactGroupMasterId,
      label: item?.name,
    })) || []),
  ];
  //Send messsage product id options
  const options =
    productImgList.map((item) => ({
      value: item?.productRetailerId,
      label: item?.imageLink ? (
        <>
          {item.title} - {item?.productRetailerId} -{" "}
          <img
            src={item.imageLink}
            alt={item.title}
            style={{ width: "30px" }}
          />{" "}
        </>
      ) : (
        `${item.title} - ${item?.productRetailerId} - No Image URL`
      ),
    })) || [];

  const handleClose = () => {
    setShow(false);
    setErrors({});
    clearData();
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };
  const handleCloseview = () => {
    setViewModal(false);
  };

  const modalHandleforEdit = (record, isEdit) => {
    setDescription(record?.description);
    setisEdited(isEdit);
    setId(record?.messageTemplateId);
    setName(record?.templateName);
    setBody(record?.body);
    setHeaderCount(record?.headerCount);
    setBodyCount(record?.bodyCount);
    setHeader(record?.header);
    setIsTamplate(record?.isTemplate);
    setFooter(record?.footer);
    setCategory(record?.category);
    setFooterCount(record?.footerCount);
    setLanguage(record?.language);
    setShow(true);
  };

  const modalHandleforSend = (record) => {
    setIsTamplate(record?.isTemplate);
    setId(record?.messageTemplateId);
    getProductImageList(record?.messageTemplateId);
    getAllConatctGroup();
    const headerCount = record?.headerCount;
    setSendHeaderCount(headerCount);
    const bodyCount = record?.bodyCount;
    setSendBodyCount(bodyCount);
    const footerCount = record?.footerCount;
    setSendFooterCount(footerCount);
    getContactAllData();
    // Initialize header inputs with data from record
    const headerInputsData = record?.header || [];
    const headerInputs = [];
    for (let i = 0; i < headerCount; i++) {
      const text = headerInputsData[i]?.parameters?.[0]?.text || "";
      headerInputs.push({ text });
    }
    setSendHeader(headerInputs);

    const bodyInputsData = record?.body || [];
    const bodyInputs = [];
    for (let i = 0; i < bodyCount; i++) {
      const text = bodyInputsData[i]?.parameters?.[0]?.text || "";
      bodyInputs.push({ text });
    }
    setSendBody(bodyInputs);

    const footerInputsData = record?.footer || [];
    const footerInputs = [];
    for (let i = 0; i < footerCount; i++) {
      const text = footerInputsData[i]?.parameters?.[0]?.text || "";
      footerInputs.push({ text });
    }
    setSendFooter(footerInputs);

    setSendModal(true);
  };
  const handleHeaderInputChange = (index, e) => {
    const { value } = e.target;
    setSendHeader((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].text = value;
      return updatedInputs;
    });
  };

  const handleBodyInputChange = (index, e) => {
    const { value } = e.target;
    setSendBody((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].text = value;
      return updatedInputs;
    });
  };
  const handleFooterInputChange = (index, e) => {
    const { value } = e.target;
    setSendFooter((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].text = value;
      return updatedInputs;
    });
  };

  const handleClosesend = () => {
    clearSendData();
    setSendModal(false);
  };

  const handleButtonClick = () => {
    setAdditionalInputs([
      ...additionalInputs,
      { categoryname: "", product: [] },
    ]);
  };
  const handlecategoryChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...additionalInputs];
    newInputs[index][name] = value;
    setAdditionalInputs(newInputs);
    setCategoryname(newInputs);
  };

  const handleProductChange = (index, selectedOption) => {
    const newInputs = [...additionalInputs];
    newInputs[index].product = selectedOption;
    setAdditionalInputs(newInputs);
    setProduct(newInputs);
  };

  const handleDeleteButtonClick = (index) => {
    const newInputs = [...additionalInputs];
    newInputs.splice(index, 1);
    setAdditionalInputs(newInputs);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Upload Product
    </Tooltip>
  );
  const renderTooltips = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Send Message
    </Tooltip>
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPageAll(newPaginationModel.page + 1); // Adjust page number (0-based index to 1-based index)
    setPageSizeAll(newPaginationModel.pageSize);
  };

  const handleRowSelectionModelChange = (rows) => {
    setSelectedRows(rows);
    setContacts(rows?.map((row) => row));
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Message Template</h2>
          <div>
            <Buttons
              type="button"
              className="ms-2"
              onClick={() => {
                modalHandle(false);
              }}
              content="Add New"
            />
          </div>
        </div>
        <div className="">
          <CommonTable
            columns={columnss}
            pagination={true}
            data={messageTemplates}
            defaultSortField="messageTemplateId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            progressPending={loadingMessage}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* Add Edit Modal */}
        <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add" : "Edit"} Message Template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        checked={isTamplate}
                        onChange={(e) => setIsTamplate(e.target.checked)}
                      />
                      <label className="form-check-label" for="inlineRadio1">
                        isTemplate
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="template name"
                    >
                      <Inputtext
                        type="text"
                        label="Template name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors?.name && (!name || spaceInput?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  {isTamplate === true && (
                    <>
                      <div className="col-md-8">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="header"
                        >
                          <Inputtext
                            type="text"
                            label="Header *"
                            value={header}
                            onChange={(e) => setHeader(e.target.value)}
                          />
                          {errors?.header &&
                          (!header || spaceInput?.test(header)) ? (
                            <span className="error">{errors?.header}</span>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="header"
                        >
                          <Inputtext
                            type="text"
                            label="Header count *"
                            value={headerCount}
                            onChange={(e) => setHeaderCount(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-8">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="Body"
                        >
                          <Inputtext
                            type="text"
                            label="Body *"
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                          />
                          {errors?.body && (!body || spaceInput?.test(body)) ? (
                            <span className="error">{errors?.body}</span>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="Body"
                        >
                          <Inputtext
                            type="text"
                            label="Body Count *"
                            value={bodyCount}
                            onChange={(e) => setBodyCount(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-8">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="footer"
                        >
                          <Inputtext
                            type="text"
                            label="Footer *"
                            value={footer}
                            onChange={(e) => setFooter(e.target.value)}
                          />
                          {errors?.footer &&
                          (!footer || spaceInput?.test(footer)) ? (
                            <span className="error">{errors?.footer}</span>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="footer"
                        >
                          <Inputtext
                            type="text"
                            label="Footer Count*"
                            value={footerCount}
                            onChange={(e) => setFooterCount(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="category"
                        >
                          <Inputtext
                            type="text"
                            label="Category *"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          />
                          {errors?.category &&
                          (!category || spaceInput?.test(category)) ? (
                            <span className="error">{errors?.category}</span>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <Form.Group
                          className="mb-3 quform-element "
                          controlId="language"
                        >
                          <Inputtext
                            type="text"
                            label="Language *"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                          />
                          {errors?.language &&
                          (!language || spaceInput?.test(language)) ? (
                            <span className="error">{errors?.language}</span>
                          ) : null}
                        </Form.Group>
                      </div>
                    </>
                  )}
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Description"
                    >
                      <Inputtext
                        type="text"
                        label="Description: *"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errors?.description &&
                      (!description || spaceInput?.test(description)) ? (
                        <span className="error">{errors?.description}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : isEdit ? (
                          "Update"
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* Send Message Modal */}
        <Modal show={sendModal} onHide={handleClosesend} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Send Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSendMessage}>
              <div className="view-popup">
                <div className="row">
                  {isTamplate === true ? (
                    <>
                      <div className="row">
                        <div className="row">
                          {sendheaderCount && (
                            <>
                              <div className="col-md-4">
                                <p>Header Count * :{sendheaderCount}</p>
                              </div>
                              {sendheader?.map((input, index) => (
                                <div className="col-md-8">
                                  <div className="mb-2">
                                    <>
                                      <input
                                        className="form-control ms-2"
                                        value={input?.text}
                                        onChange={(e) =>
                                          handleHeaderInputChange(index, e)
                                        }
                                      />
                                      {errors?.sendheader &&
                                      (!input.text ||
                                        spaceInput.test(input.text)) ? (
                                        <span className="error ms-2">
                                          {errors.sendheader}
                                        </span>
                                      ) : null}
                                    </>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        <div className="row">
                          {sendbodyCount && (
                            <>
                              <div className="col-md-4">
                                <p>Body Count * :{sendbodyCount}</p>
                              </div>

                              <div className="col-md-8">
                                {sendbody.map((input, index) => (
                                  <div className="mb-2">
                                    <>
                                      <input
                                        className="form-control ms-2"
                                        value={input.text}
                                        onChange={(e) =>
                                          handleBodyInputChange(index, e)
                                        }
                                      />
                                      {errors?.sendbody &&
                                      (!input.text ||
                                        spaceInput.test(input.text)) ? (
                                        <span className="error ms-2">
                                          {errors.sendbody}
                                        </span>
                                      ) : null}
                                    </>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          {sendfooterCount && (
                            <>
                              <div className="col-md-4">
                                <p>footer Count * :{sendfooterCount}</p>
                              </div>
                              <div className="col-md-8">
                                {sendfooter?.map((input, index) => (
                                  <div className="mb-2 ">
                                    <>
                                      <input
                                        className="form-control ms-2"
                                        value={input.text}
                                        onChange={(e) =>
                                          handleFooterInputChange(index, e)
                                        }
                                      />
                                      {errors?.sendfooter &&
                                      (!input.text ||
                                        spaceInput?.test(input.text)) ? (
                                        <span className="error ms-2">
                                          {errors.sendfooter}
                                        </span>
                                      ) : null}
                                    </>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Header Product image*</label>
                          </div>
                          <div className="col-md-8">
                            <Select
                              className="ms-2"
                              options={productImgOptions}
                              value={productimg}
                              onChange={(selectedOption) =>
                                setProductimg(selectedOption)
                              }
                            />
                            {errors?.productimg &&
                            (!productimg || spaceInput?.test(productimg)) ? (
                              <span className="error ms-2">
                                {errors?.productimg}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label>
                          Add Category*
                          <span
                            className="toggle-options ms-1 text-success"
                            onClick={handleButtonClick}
                          >
                            <FaPlusCircle />
                          </span>
                        </label>
                      </div>
                      <div>
                        {additionalInputs?.map((input, index) => (
                          <div className=" card p-3 mb-2" key={index}>
                            <div className="d-flex mb-2 justify-content-end">
                              {index === 0 ? (
                                ""
                              ) : (
                                <AiFillDelete
                                  className=" text-danger "
                                  onClick={() => handleDeleteButtonClick(index)}
                                />
                              )}
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <label>Name* :</label>
                              </div>
                              <div className="col-md-8">
                                <p>
                                  <input
                                    type="text"
                                    className=" mb-2 form-control"
                                    value={input.categoryname}
                                    name="categoryname"
                                    onChange={(e) =>
                                      handlecategoryChange(index, e)
                                    }
                                    placeholder=""
                                  />
                                  {errors?.categoryname &&
                                  (!input.categoryname ||
                                    spaceInput?.test(input.categoryname)) ? (
                                    <span className="error">
                                      {errors?.categoryname}
                                    </span>
                                  ) : null}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>Product* :</p>
                              </div>
                              <div className="col-md-8">
                                {options && (
                                  <>
                                    <Select
                                      isMulti
                                      options={options?.filter(
                                        (option) =>
                                          !additionalInputs?.some(
                                            (input, i) =>
                                              i !== index &&
                                              input.product?.some(
                                                (prod) =>
                                                  prod.value === option.value
                                              )
                                          )
                                      )}
                                      value={input.product}
                                      onChange={(selectedOption) =>
                                        handleProductChange(
                                          index,
                                          selectedOption
                                        )
                                      }
                                    />
                                    {errors?.product &&
                                    (!input.product ||
                                      input.product.length === 0) ? (
                                      <span className="error">
                                        {errors?.product}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {isTamplate === false && (
                    <>
                      <div className="col-md-4">
                        <p>Message * :</p>
                      </div>
                      <div className="col-md-8">
                        <p>
                          <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="mb-2 ms-2 form-control"
                            placeholder=""
                          />
                          {errors?.message &&
                          (!message || spaceInput?.test(message)) ? (
                            <span className="error">{errors?.message}</span>
                          ) : null}
                        </p>
                      </div>
                    </>
                  )}
                  <div className="col-md-4">
                    <p>Conatct Group * :</p>
                  </div>
                  <div className="col-md-8">
                    <p>
                      <Select
                        className="ms-2"
                        options={contactGroupOptions}
                        value={contactgroup}
                        isDisabled={allContacts || selectedRows.length !== 0}
                        onChange={(selectedOption) =>
                          setContactgroup(selectedOption)
                        }
                      />
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p>
                      All Contact * :
                      <input
                        className="form-check-input ms-2 me-2 "
                        style={{
                          width: "20px",
                          height: "20px",
                          borderColor: "#0000004f",
                        }}
                        type="checkbox"
                        disabled={
                          contactgroup.value || selectedRows.length !== 0
                        }
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        checked={allContacts}
                        onChange={(e) => setAllContacts(e.target.checked)}
                      />
                    </p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="d-flex justify-content-end mb-2">
                      <input
                        type="text"
                        placeholder="Search.... "
                        value={searchText}
                        className="form-control"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: "200px", padding: "5px" }}
                      />
                    </div>
                    <div style={{ width: "100%" }} className="">
                      <DataGrid
                        slots={{
                          noRowsOverlay: (
                            <Box sx={{ mt: 1 }}>No Data found.</Box>
                          ),
                        }}
                        columns={subColumn}
                        disableColumnMenu={true}
                        sx={{
                          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                            {
                              display: "none",
                            },
                        }}
                        getRowId={(row) => row?.mobileNo}
                        isRowSelectable={(row) =>
                          !allContacts && contactgroup.value === undefined
                        }
                        rows={contactAll}
                        rowCount={totalPagesAll}
                        checkboxSelection
                        loading={loading}
                        onPaginationModelChange={handlePaginationModelChange}
                        keepNonExistentRowsSelected
                        rowSelectionModel={selectedRows}
                        onRowSelectionModelChange={
                          handleRowSelectionModelChange
                        }
                        pageSizeOptions={[5, 10, 50]}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              page: pageAll - 1,
                              pageSize: pageSizeAll,
                            },
                          },
                        }}
                        paginationMode="server"
                        // onSelectionModelChange={handleRowSelection}
                        selectionModel={selectedRows}
                        {...contactAll}
                      />
                    </div>
                    {errors?.contactgroup &&
                    (!contactgroup || spaceInput?.test(contactgroup)) ? (
                      <span className="error">{errors?.contactgroup}</span>
                    ) : null}
                  </div>
                  <hr />
                  <div className="col-12 text-end mt-3">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClosesend}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={sendLoading}
                      content={
                        sendLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>TemplateName :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.templateName}</p>
                </div>
                {isTamplate === true && (
                  <>
                    {" "}
                    <div className="col-md-4">
                      <p>Header :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{viewData?.header}</p>
                    </div>
                    <div className="col-md-4">
                      <p>Body :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{viewData?.body}</p>
                    </div>
                    <div className="col-md-4">
                      <p>Footer :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{viewData?.footer}</p>
                    </div>
                    <div className="col-md-4">
                      <p>Category :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{viewData?.category}</p>
                    </div>
                    <div className="col-md-4">
                      <p>Language :</p>
                    </div>
                    <div className="col-md-8">
                      <p>{viewData?.language}</p>
                    </div>
                  </>
                )}
                <div className="col-md-4">
                  <p>Description:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.description} </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="  Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          confirmModel={deleteMessageTemplate}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default MessageTemplate;
