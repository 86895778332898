import { Navigate } from "react-router-dom";
import store from "../redux/store/store";

export const PublicRoute = ({ children }) => {
    const token = store.getState().login.auth.token;
    if (token) {
      return <Navigate to="/" />;
    }
    return children;
  };
  