import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";
import store from "../../redux/store/store";

const CompanyEnv = () => {
  const [id, setId] = useState();
  const [metaWAVerifyToken, setmetaWAVerifyToken] = useState("");
  const [metaWAAccessToken, setmetaWAAccessToken] = useState("");
  const [metaWASenderPhoneNumberId, setmetaWASenderPhoneNumberId]= useState("");
  const [metaWAWabaId, setmetaWAWabaId] = useState("");
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [companyEnv, setCompanyEnv] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [viewData, setviewData] = useState("");
  const spaceInput = /^\s*$/;
  const loginUserDetail = store.getState().login.auth;
  const companyId = loginUserDetail?.companyId;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyEnvsList();
  }, []);

  const clearData = () => {
    setmetaWAAccessToken("");
    setSubmitLoading(false)
    setmetaWASenderPhoneNumberId("");
    setmetaWAVerifyToken("");
    setmetaWAWabaId("");
    setLoading(false)
    setErrors({});
  };
  
  const columns = [
    {
      name: "envId",
      selector: (row) => row.envId,
      // sortable: true,
    },
    {
      name: "Meta WA Waba Id",
      selector: (row) => (row.metaWAWabaId ? row.metaWAWabaId : "-"),
      // sortable: true,
    },
    {
      name: "Meta WA Sender Phone Number Id",
      selector: (row) =>
        row.metaWASenderPhoneNumberId ? row.metaWASenderPhoneNumberId : "-",
      // sortable: true,
    },
    {
      name: "Meta WA Access Token",
      selector: (row) => (row.metaWAAccessToken ? row.metaWAAccessToken : "-"),
      // sortable: true,
    },
    {
      name: "Meta WA Verify Token",
      selector: (row) => (row.metaWAVerifyToken ? row.metaWAVerifyToken : "-"),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.envId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewCompanyEnv()} />
          </div>

          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d?.envId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

    /*-------------------- API CALL START HERE --------------------*/
    
  const getCompanyEnvsList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Env + `/company/${companyId}`
      );
      setLoading(false)
      setCompanyEnv(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const deleteCompanyEnv = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.Env + `/${id}`
      );
      clearData();
      getCompanyEnvsList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      metaWAVerifyToken,
      metaWAAccessToken,
      metaWASenderPhoneNumberId,
      metaWAWabaId,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true)
      const payload = {
        companyId: companyId,
        metaWAVerifyToken: metaWAVerifyToken,
        metaWAAccessToken: metaWAAccessToken,
        metaWASenderPhoneNumberId: metaWASenderPhoneNumberId,
        metaWAWabaId: metaWAWabaId,
      };
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.Env,
            payload
          );
          clearData();
          setId("");
          setSubmitLoading(false)
          getCompanyEnvsList();
          handleClose();
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false)
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            Constants.Env + `/${id}`,
            payload
          );
          clearData();
          setId("");
          getCompanyEnvsList();
          setSubmitLoading(false)
          handleClose();
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          setSubmitLoading(false)
          console.error(error.message);
        }
      }
      // setErrors({});
    }
  }; 

  const viewCompanyEnv = async () => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.Env + `/company/${companyId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  /*-------------------- API CALL END HERE --------------------*/
  const handleClose = () => {
    clearData();
    setShow(false);
    setErrors({});
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };

  const modalHandleforEdit = (record, isEdit) => {
    setShow(true);
    setId(record?.envId);
    setisEdited(isEdit);
    setmetaWAAccessToken(record?.metaWAAccessToken);
    setmetaWASenderPhoneNumberId(record?.metaWASenderPhoneNumberId);
    setmetaWAVerifyToken(record?.metaWAVerifyToken);
    setmetaWAWabaId(record?.metaWAWabaId);
  };
  const handleCloseview = () => {
    setViewModal(false);
  };


  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0"> Company Environment Variables</h2>
          <Buttons
            type="button"
            onClick={() => {
              modalHandle(false);
            }}
            content="Add New"
            disabled={companyEnv}
          />
        </div>
        {companyEnv ? (
          <div className="table-responsive">
            <CommonTable
              columns={columns}
              data={[companyEnv]}
              progressPending={loading}
              defaultSortField="envId"
              defaultSortAsc={true}
              highlightOnHover
              dense
            />
          </div>
        ) : (
          <div> No data found! </div>
        )}

        {/* Add Edit Modal */}
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit
                ? "Add Company Environment Variables"
                : "Edit Company Environment Variables"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="metaWAWabaId"
                    >
                      <Inputtext
                        type="text"
                        label="metaWAWabaId *"
                        value={metaWAWabaId}
                        onChange={(e) => setmetaWAWabaId(e.target.value)}
                      />
                      {errors?.metaWAWabaId &&
                      (!metaWAWabaId || spaceInput?.test(metaWAWabaId)) ? (
                        <span className="error">{errors?.metaWAWabaId}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="MetaWA Verify Token"
                    >
                      <Inputtext
                        type="text"
                        label="MetaWA Verify Token *"
                        value={metaWAVerifyToken}
                        onChange={(e) => setmetaWAVerifyToken(e.target.value)}
                      />
                      {errors?.metaWAVerifyToken &&
                      (!metaWAVerifyToken ||
                        spaceInput?.test(metaWAVerifyToken)) ? (
                        <span className="error">
                          {errors?.metaWAVerifyToken}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="MetaWA Access Token"
                    >
                      <Inputtext
                        type="text"
                        label="MetaWA Access Token *"
                        value={metaWAAccessToken}
                        onChange={(e) => setmetaWAAccessToken(e.target.value)}
                      />
                      {errors?.metaWAAccessToken &&
                      (!metaWAAccessToken ||
                        spaceInput?.test(metaWAAccessToken)) ? (
                        <span className="error">
                          {errors?.metaWAAccessToken}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Mobile No"
                    >
                      <Inputtext
                        type="text"
                        label="MetaWA Sender PhoneNumber Id *"
                        value={metaWASenderPhoneNumberId}
                        onChange={(e) =>
                          setmetaWASenderPhoneNumberId(e.target.value)
                        }
                      />
                      {errors?.metaWASenderPhoneNumberId &&
                      (!metaWASenderPhoneNumberId ||
                        spaceInput?.test(metaWASenderPhoneNumberId)) ? (
                        <span className="error">
                          {errors?.metaWASenderPhoneNumberId}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content=" Cancel"
                    />
                    <Buttons type="submit"  disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      } />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>Meta WA Waba Id :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.metaWAWabaId}</p>
                </div>
                <div className="col-md-5">
                  <p>Meta WA Sender Phone Number Id :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.metaWASenderPhoneNumberId}</p>
                </div>
                <div className="col-md-5">
                  <p>Meta WA Access Token :</p>
                </div>
                <div className="col-md-7 text-break">
                  <p>{viewData?.metaWAAccessToken}</p>
                </div>
                <div className="col-md-5">
                  <p>Meta WA Verify Token :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.metaWAVerifyToken}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          centered
          confirmModel={deleteCompanyEnv}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default CompanyEnv;
