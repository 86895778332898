import React, { useState } from "react";
import "./login.css";
import { BiSolidLockAlt } from "react-icons/bi";
import { HiOutlineUser } from "react-icons/hi2";
import Validation from "../../common/validation";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../../common/constant";
import { setAuth } from "../../redux/slice/loginSlice";
import CommonApiServices from "../../common/apiServices";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  
  /*-------------------- API CALL START HERE --------------------*/
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({ email, password });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);

      const payload = {
        email: email,
        password: password,
      };
      try {
        const resJson = await CommonApiServices.postData(
          Constants.SaveLogin,
          payload
        );
        // clearData();
        if (resJson?.status === Constants.CODE_SUCCESS) {
          dispatch(setAuth(resJson?.data));
          if (resJson?.data?.isSuperAdmin) {
            navigate("/");
          } else if (resJson?.data?.isFirstLogin) {
            navigate("/changepassword", { state: { isFirstLogin: "true" } });
          } else {
            navigate("/");
          }
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
          setEmail("");
          setPassword("");
        }
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };
  /*-------------------- API CALL END HERE --------------------*/
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-details text-center p-5">
              <div>
                <h1>
                  whatsapp
                  <br /> database
                </h1>
                <img
                  src="/assets/img/login.jpg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-form-box p-5">
              <div className="log-form-box p-5">
                <div className="login-form w-100">
                  <h2>login</h2>
                  {/* <p>user</p> */}
                  <form className="log-form mt-3" onSubmit={onSubmitForm}>
                    <div className="mb-4 position-relative">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        email id
                      </label>
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        id="exampleInputEmail1"
                      />
                      <HiOutlineUser
                        style={{ color: " #051D34" }}
                        className="img-fluid login-icon"
                      />
                      {errors.email && !pattern?.test(email) ? (
                        <span className="error">{errors.email}</span>
                      ) : null}
                    </div>
                    <div className="mb-4 position-relative">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        password
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control ps-5 boxshowd"
                        id="exampleInputPassword1"
                      />
                      <BiSolidLockAlt
                        style={{ color: " #051D34" }}
                        className="img-fluid login-icon"
                      />
                      {errors?.password && !password ? (
                        <span className="error">{errors?.password}</span>
                      ) : null}
                    </div>
                    <div className="mb-3 position-relative">
                      <span className="form-label">
                        <Link
                          to="/forgotpassword"
                          className="link-underline link-underline-opacity-0 "
                        >
                          Forgot password ?
                        </Link>{" "}
                      </span>
                    </div>
                    <button
                      type="submit"
                      disabled={submitLoading}
                      className="btn btn-primary w-100 mt-3 btn-primary "
                    >
                      {submitLoading ? <Spinner animation="border" size="sm" /> :"submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
