import React, { useEffect, useState } from "react";
import "./verifaction.css";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import * as Constants from "../../common/constant";
import CommonApiServices from "../../common/apiServices";

const VerificationCode = () => {
  const [code, setCode] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  let { user } = state;

  useEffect(() => {
    if (code.length === 4) {
      callVerificationAPI();
    }
  }, [code]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isCodeSent, minutes, seconds]);

  const callVerificationAPI = async () => {
    try {
      const resJson = await CommonApiServices.postData(Constants.VerifyOTP, {
        email: user?.email,
        otp: code,
      });

      if (resJson?.status === Constants.CODE_SUCCESS) {
        navigate("/resetpassword", { state: { email: user?.email } });
        isCodeSent = false;
      }
      //  else {
      //   setError("Invalid OTP. Please try again.");
      // }
    } catch (error) {
      console.log("callVerificationAPI : =>", error);
    }
  };

  const resendOTP = async () => {
    const resJson = await CommonApiServices.postData(Constants.ForgotPassword, {
      email: user?.email,
    });

    if (resJson?.status === Constants.CODE_SUCCESS) {
      user = resJson?.data;
    }
    setMinutes(1);
    setSeconds(0);
    setIsCodeSent(false);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-details text-center p-5">
              <div>
                <h1>
                  whatsapp
                  <br /> database
                </h1>
                <img
                  src="/assets/img/email_based.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-form-box p-5">
              <div className="log-form-box p-5">
                <div className="login-form w-100">
                  <h2>Verification Code</h2>
                  <p>
                    Please type the verification code sent to
                    prelookstudio@gmail.com
                  </p>
                  <div className="verifaction-box">
                    <div className="verifaction-box-input mt-4 text-center">
                      <OTPInput
                        value={code}
                        onChange={(otp) => {
                          setCode(otp);
                          // setError('');
                        }}
                        numInputs={4}
                        renderSeparator={<span> </span>}
                        inputStyle={`inputStyle form-control ${
                          code.length === 4 &&
                          (user?.otp === code ? "otp-green" : "otp-red")
                        }`}
                        style={{ color: "red" }}
                        renderInput={(props) => <input {...props} />}
                      />
                      {error && <div className="error mt-1 ms-2">{error}</div>}
                    </div>
                    <div className="">
                      {(minutes === 0 && seconds === 0) || isCodeSent ? (
                        <p
                          type="button"
                          // disabled={isCodeSent}
                          className="text-center mt-4 text-primary"
                          onClick={resendOTP}
                        >
                          Resend Code ?
                        </p>
                      ) : (
                        <p className="text-center mt-4 text-primary">
                          Time Remaining:
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
