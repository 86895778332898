import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: [],
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setAuth: (state, action) => ({
      ...state,
      auth: action.payload,
    }),
    logout: (state) => {
      state.auth = {};
    },
  },
});

export const { setAuth, logout } = loginSlice.actions;
export const selectDemo = (state) => state.auth;

export default loginSlice.reducer;
