import React from "react";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaLongArrowAltDown } from "react-icons/fa";

const CommonTable = ({
  columns,
  data,
  selectableRows,
  defaultSortField,
  defaultSortAsc,
  selectableRowsHighlight,
  onSelectedRowsChange,
  selectableRowDisabled,
  subHeaderComponent,
  subHeader,
  striped,
  title,
  pagination,
  paginationPerPage,
  onChangePage,
  paginationTotalRows,
  onChangeRowsPerPage,
  selectableRowsComponent,
  selectableRowsComponentProps,
  progressPending,
  selectableRowSelected,
  noHeader
}) => {
  const customStyles = {
    header: {
      style: {
        minHeight: "70px",
      },
    },
    headRow: {
      style: {
   paddingTop:"10px",
   paddingBottom:"10px",
        borderTopStyle: "groove",
        borderTopWidth: "1px",
        fontWeight: "700",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#f0f0f0",
        padding: "10px",
        borderBottomStyle: "solid rgb(224 224 224)",
        borderBottomWidth: "1px",
        borderTopStyle: "solid rgb(224 224 224)", // Add top border
        borderTopWidth: "1px",
        // marginBottom: "2px",
      },
    },
    subHeaderInput: {
      style: {
        width: "200px",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid #ccc",
      },
    },
  };
  const CustomLoader = () => (
    <div style={{ padding: '24px' }}>
      <Spinner />
    </div>
  );

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        title={title}
        customStyles={customStyles}
        defaultSortField={defaultSortField}
        sortIcon={<FaLongArrowAltDown />}
        defaultSortAsc={defaultSortAsc}
        pagination={pagination}
        selectableRows={selectableRows}
        selectableRowsComponentProps={selectableRowsComponentProps}
        selectableRowsComponent={selectableRowsComponent}
        highlightOnHover
        striped={striped}
        onSelectedRowsChange={onSelectedRowsChange}
        paginationRowsPerPageOptions={[10, 50, 100,250,1000]}
        dense
        selectableRowsHighlight={selectableRowsHighlight}
        noDataComponent={"No Data Found"}
        persistTableHead={true}
        selectableRowSelected={selectableRowSelected}
        paginationTotalRows={paginationTotalRows}
        onChangePage={onChangePage}
        selectableRowsNoSelectAll={false} 
        paginationPerPage={paginationPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        progressPending={progressPending}
        progressComponent={<CustomLoader />}
        noHeader={noHeader}
        paginationServer={true}
        selectableRowDisabled={selectableRowDisabled}
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
      />
    </div>
  );
};

export default CommonTable;
