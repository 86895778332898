import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import { useParams } from "react-router-dom";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import * as XLSX from "xlsx";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";
import store from "../../redux/store/store";
import { imageFormat, imageMessage } from "../../common/uploadImage";
import { FormLabel } from "@mui/material";

const ProductTemplate = () => {
  const loginUserDetail = store.getState().login.auth;
  const [templateProductId, setId] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [availability, setAvailability] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [price, setPrice] = useState("");
  const [productRetailerId, setProductRetailerId] = useState("");
  const [condition, setCondition] = useState("");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [imageLinks, setImageLinks] = useState("");
  const [imgupload, setImgupload] = useState();
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [fileshow, setFileShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [deleteModalImg, setDeleteModalImg] = useState(false);
  const [viewModalImg, setViewModalImg] = useState(false);
  const [isEditImg, setisEditedImg] = useState(false);
  const [viewData, setviewData] = useState("");
  const [viewDataImg, setviewDataImg] = useState("");
  const imageInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const spaceInput = /^\s*$/;
  const [templateProducts, setTemplateProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [searchImg, setSearchImg] = useState("");
  const [imageList, setImageList] = useState([]);
  const [pageimg, setPageImg] = useState(1);
  const [pageSizeImg, setPageSizeImg] = useState(10);
  const [totalPagesImg, setTotalPagesImg] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitImgLoading, setSubmitImgLoading] = useState(false);
  const [ImgLoading, setImgLoading] = useState(true);
  const [productLoading, setProductLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    getTemplateProductList();
  }, [page, pageSize, search, pageimg]);

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      const firstRowImageLink = selectedRows[0]?.imageLink;
      if (firstRowImageLink !== imageLink) {
        setImageLink(firstRowImageLink);
      }
    }
  }, [selectedRows]);

  useEffect(() => {
    getImageList();
  }, [pageimg, searchImg, pageSizeImg]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.templateProductId,
      // sortable: true,
    },
    {
      name: "Product Retailer Id",
      selector: (row) => (row.productRetailerId ? row.productRetailerId : "-"),
      // sortable: true,
    },
    {
      name: "Image",
      cell: (d) => (
        <img
          src={d.imageLink}
          alt={d.title}
          className="mt-2 mb-2"
          style={{ width: "80px", height: "80px" }}
        />
      ),
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },
    {
      name: "Title",
      selector: (row) => (row.title ? row.title : "-"),
      // sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : "-"),
      // sortable: true,
    },
    {
      name: "Availability",
      selector: (row) => (row.availability ? row.availability : "-"),
      // sortable: true,
    },
    {
      name: "Condition",
      selector: (row) => (row.condition ? row.condition : "-"),
      // sortable: true,
    },
    {
      name: "Price",
      selector: (row) => (row.price ? row.price : "-"),
      // sortable: true,
    },
    {
      name: "Link",
      selector: (row) => (row.link ? row.link : "-"),
      // sortable: true,
    },
    {
      name: "Image Link",
      // width: "40%",
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },

    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.templateProductId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView
              onClick={() => viewTemplateProduct(d?.templateProductId)}
            />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d?.templateProductId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  const columnsImg = [
    {
      name: "No",
      selector: (row) => row.imageGalleryId,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },
    {
      name: "Image",
      cell: (d) => (
        <img
          src={d.imageLink}
          alt={d.title}
          className="mb-2 mt-2"
          style={{ width: "80px", height: "80px" }}
        />
      ),
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },
    {
      name: "Image Link",
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.imageGalleryId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEditImg(d, true);
                setShowImg(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewImageGallery(d?.imageGalleryId)} />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d?.imageGalleryId);
                setDeleteModalImg(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];
  /*-------------------- API CALL START HERE --------------------*/
  const getTemplateProductList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.TemplateProduct +
          `/${id}/products/company/${loginUserDetail?.companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setProductLoading(false);
      setTotalPages(pageCount);
      setTemplateProduct(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getImageList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.ImageGallery +
          `/company/${loginUserDetail?.companyId}?page=${page}&pageSize=${pageSize}&search=${searchImg}`
      );
      const pageCount = response?.count;
      setImgLoading(false);
      setTotalPagesImg(pageCount);
      setImageList(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const viewTemplateProduct = async (templateProductId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.TemplateProduct + `/${id}/products/${templateProductId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteTemplateProduct = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.TemplateProduct + `/${id}/products/${templateProductId}`
      );
      clearData();
      getTemplateProductList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      productRetailerId,
      title,
      description,
      availability,
      condition,
      price,
      link,
      imageLink,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      const payload = {
        companyId: loginUserDetail?.companyId,
        messageTemplateId: id,
        productRetailerId: productRetailerId,
        title: title,
        description: description,
        availability: availability,
        condition: condition,
        price: price,
        link: link,
        imageLink: imageLink,
      };
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.TemplateProduct + `/${id}/products`,
            payload
          );
          clearData();
          setId("");
          getTemplateProductList();
          setImageLink("");
          setSubmitLoading(false);
          handleClose();
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            Constants.TemplateProduct + `/${id}/products/${templateProductId}`,
            payload
          );
          clearData();
          setId("");
          getTemplateProductList();
          handleClose();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }
      // setErrors({});
    }
  };

  const onSubmitFormImg = async (e) => {
    e.preventDefault();
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", imageLinks);
    formData.append("companyId", loginUserDetail?.companyId);
    const validationErrors = Validation({
      name,
      imageLinks,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitImgLoading(true);
      if (!isEditImg) {
        try {
          const response = await CommonApiServices.postForm(
            Constants.ImageGallery,
            formData
          );
          clearData();
          setId("");
          getImageList();
          setSubmitImgLoading(false);
          setShowImg(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          setSubmitImgLoading(false);
          console.error(error.message);
        }
      } else {
        try {
          const response = await CommonApiServices.putForm(
            Constants.ImageGallery +
              `/${id}/company/${loginUserDetail?.companyId}`,
            formData
          );
          clearData();
          setId("");
          getImageList();
          handleClose();
          setSubmitImgLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          setSubmitImgLoading(false);
          console.error(error.message);
        }
      }
      // setErrors({});
    }
  };

  const viewImageGallery = async (id) => {
    try {
      setViewModalImg(true);
      const response = await CommonApiServices.getData(
        Constants.ImageGallery + `/${id}`
      );
      setviewDataImg(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteImageGallery = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.ImageGallery + `/${id}`
      );
      clearData();
      getImageList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };

  /* export Excel File */
  const exportData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.TemplateProduct}/${id}/products/all/company/${loginUserDetail?.companyId}`
      );
      const filteredData = response.data.map((item) => ({
        templateProductId: item?.templateProductId,
        productRetailerId: item?.productRetailerId,
        messageTemplateId: item?.messageTemplateId,
        title: item?.title,
        description: item?.description,
        availability: item?.availability,
        condition: item?.condition,
        price: item?.price,
        link: item?.link,
        imageLink: item?.imageLink,
      }));
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "templateData.xlsx");
      setLoading(false);
      swal(`${response.message}`, "", "success");
    } catch (error) {
      console.error(error.message);
    }
  };

  /* Import Excel File*/
  const handleFile = async () => {
    if (!file.name.endsWith(".xlsx")) {
      swal("Invalid file format. Please upload an Excel file.", "", "warning");
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const columnHeaders = [
          "productRetailerId",
          "title",
          "description",
          "availability",
          "condition",
          "price",
          "link",
          "imageLink",
        ];
        const headersInSheet = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
        const isValidFile = columnHeaders.every((header) =>
          headersInSheet.includes(header)
        );
        if (!isValidFile) {
          swal(
            "Invalid file format. Please ensure the file contains columns: productRetailerId, title, description, availability, condition, price, link, imageLink",
            "",
            "warning"
          );
          return;
        }
        const excelData = XLSX.utils.sheet_to_json(ws);
        const filteredData = excelData?.every(
          (item) => item?.messageTemplateId == id
        );
        if (!filteredData) {
          swal(
            "No data matching the specified messageTemplateId.",
            "",
            "warning"
          );
          return;
        }
        const seenIds = {};
        for (const item of excelData) {
          if (seenIds[item.productRetailerId]) {
            swal("Duplicate productRetailerId found.", "", "warning");
            return;
          } else {
            seenIds[item.productRetailerId] = true;
          }
        }
        const formattedData = excelData?.map((item) => ({
          templateProductId: item?.templateProductId,
          productRetailerId: item?.productRetailerId,
          messageTemplateId: item?.messageTemplateId,
          title: item?.title,
          description: item?.description,
          availability: item?.availability,
          condition: item?.condition,
          price: item?.price,
          link: item?.link,
          imageLink: item?.imageLink,
        }));
        const response = await CommonApiServices.authPostData(
          `${Constants.TemplateProduct}/${id}/products/upload/multiple`,
          { products: formattedData }
        );
        swal(`${response.message}`, "", "success");
        getTemplateProductList();
        setFile("");
        setFileShow(false);
      } catch (error) {
        console.error("Error parsing the Excel file:", error);
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  /*-------------------- API CALL END HERE --------------------*/

  const clearData = () => {
    setProductRetailerId("");
    setTitle("");
    setAvailability("");
    setSubmitImgLoading(false);
    setSubmitLoading(false);
    setCondition("");
    if (imageInputRef.current) imageInputRef.current.value = "";
    setFile("");
    setDescription("");
    setPrice("");
    setLink("");
    setImageLinks("");
    setName("");
    setImageLink("");
    setErrors({});
  };

  const handleClose = () => {
    clearData();
    setShow(false);
    setImageLink("");
    setErrors({});
  };

  const handleCloseImg = () => {
    clearData();
    setShowImg(false);
    setErrors({});
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const handleCloseDeleteImg = () => {
    setDeleteModalImg(false);
  };
  // Import Excel
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };
  // imageupload
  const onChangeImage = (event) => {
    const selectedFile = event.target.files[0];
    if (!event.target.files[0]?.name?.match(imageFormat)) {
      setImageLinks("");
      setImgupload(false);
      if (imageInputRef.current) imageInputRef.current.value = "";
      swal(imageMessage, "", "warning");
      return false;
    }
    const formData = new FormData();
    formData.append("imageLinks", event.currentTarget.files[0]);
    setImageLinks(selectedFile);
    setImgupload(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const file = event.target.files[0];
        const width = img.width;
        const height = img.height;
        const minWidth = 500;
        const minHeight = 500;
        const maxSizeInBytes = 8 * 1024 * 1024; // 8 MB
        const recommendedWidth = 1200;
        const recommendedHeight = 628;

        if (file.size > maxSizeInBytes) {
          swal(
            "File Too Large",
            "Please upload images with a maximum size of 8 MB.",
            "error"
          );
          return;
        }

        if (width < minWidth || height < minHeight) {
          setImageLinks("");
          setFile(null);
          if (imageInputRef.current) imageInputRef.current.value = "";
          swal(
            `Minimum image size should be ${minWidth} x ${minHeight} pixels.`,
            "",
            "warning"
          );
        }
        // else if (width > recommendedWidth || height > recommendedHeight) {
        //   swal(
        //     `We recommend using an image size of ${recommendedWidth} x ${recommendedHeight} pixels for best quality.`,
        //     "",
        //     "info"
        //   );
        // }
        else {
          setFile(URL.createObjectURL(event.target.files[0]));
        }
      };
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleCloseFile = () => {
    setFileShow(false);
  };
  const modalFileHandle = async () => {
    setFileShow(true);
  };
  const modalHandle = async (isEdit) => {
    getImageList();
    setisEdited(isEdit);
    setShow(true);
  };
  const modalHandleImg = async (isEdit) => {
    setisEditedImg(isEdit);
    setShowImg(true);
  };

  const modalHandleforEdit = (record, isEdit) => {
    getImageList();
    setShow(true);
    setId(record.templateProductId);
    setisEdited(isEdit);
    setProductRetailerId(record?.productRetailerId);
    setTitle(record?.title);
    setAvailability(record.availability);
    setCondition(record?.condition);
    setDescription(record?.description);
    setPrice(record?.price);
    setLink(record?.link);
    setImageLink(record?.imageLink);
  };

  const modalHandleforEditImg = (record, isEdit) => {
    setShowImg(true);
    setId(record.imageGalleryId);
    setisEditedImg(isEdit);
    setName(record?.name);
    setImageLinks(record?.imageLink);
    setFile(record?.imageLink);
  };
  const handleCloseview = () => {
    setViewModal(false);
  };
  const handleCloseviewImg = () => {
    setViewModalImg(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };
  const handlePageChangeImg = (newPage) => {
    setPageImg(newPage);
  };
  const handleRowsPerPageChangeImg = (newPageSize) => {
    setPageSizeImg(newPageSize);
    setPageImg(1);
  };

  const handleRowSelected = useCallback((state) => {
    const selectedMobileNumbers = state?.selectedRows?.map((row) => row);
    if (selectedMobileNumbers.length > 1) {
      setSelectedRows([
        selectedMobileNumbers[selectedMobileNumbers.length - 1],
      ]);
    } else {
      setSelectedRows(selectedMobileNumbers);
    }
  }, []);

  const rowDisabledCriteria = useCallback(
    (row) => {
      if (selectedRows.length > 0 && row?.imageGalleryId) {
        return !selectedRows.some(
          (selectedRow) => selectedRow?.imageGalleryId === row.imageGalleryId
        );
      }
      return false;
    },
    [selectedRows]
  );

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Product Template</h2>
          <div className="d-flex">
            <Buttons
              type="button"
              onClick={() => {
                modalFileHandle(false);
              }}
              content="Import"
            />
            <Buttons
              type="button"
              className=" ms-2"
              onClick={exportData}
              disabled={loading}
              content={loading ? "Exporting..." : "Export"}
            />
            <Buttons
              type="button"
              className=" ms-2"
              onClick={() => {
                modalHandle(false);
              }}
              content="Add New"
            />
          </div>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={templateProducts}
            pagination={true}
            defaultSortField="templateProductId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            progressPending={productLoading}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add Template Product" : "Edit Template Product"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Product Retailer Id"
                    >
                      <Inputtext
                        type="text"
                        label="Product Retailer Id *"
                        value={productRetailerId}
                        onChange={(e) => setProductRetailerId(e.target.value)}
                      />
                      {errors?.productRetailerId &&
                      (!productRetailerId ||
                        spaceInput?.test(productRetailerId)) ? (
                        <span className="error">
                          {errors?.productRetailerId}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Title"
                    >
                      <Inputtext
                        type="text"
                        label="Title *"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      {errors?.title && (!title || spaceInput?.test(title)) ? (
                        <span className="error">{errors?.title}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="description"
                    >
                      <Inputtext
                        type="text"
                        label="Description *"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errors?.description &&
                      (!description || spaceInput?.test(description)) ? (
                        <span className="error">{errors?.description}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Availability"
                    >
                      <Inputtext
                        type="text"
                        label="Availability *"
                        value={availability}
                        onChange={(e) => setAvailability(e.target.value)}
                      />
                      {errors?.availability &&
                      (!availability || spaceInput?.test(availability)) ? (
                        <span className="error">{errors?.availability}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Condition"
                    >
                      <Inputtext
                        type="text"
                        label="Condition *"
                        value={condition}
                        onChange={(e) => setCondition(e.target.value)}
                      />
                      {errors?.condition &&
                      (!condition || spaceInput?.test(condition)) ? (
                        <span className="error">{errors?.condition}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="price"
                    >
                      <Inputtext
                        type="text"
                        label="Price *"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      {errors?.price && (!price || spaceInput?.test(price)) ? (
                        <span className="error">{errors?.price}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="link"
                    >
                      <Inputtext
                        type="text"
                        label="Link *"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                      {errors?.link && (!link || spaceInput?.test(link)) ? (
                        <span className="error">{errors?.link}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Image Link"
                    >
                      <Inputtext
                        type="text"
                        label="Image link *"
                        value={imageLink}
                        onChange={(e) => setImageLink(e.target.value)}
                      />
                      {errors?.imageLink &&
                      (!imageLink || spaceInput?.test(imageLink)) ? (
                        <span className="error">{errors?.imageLink}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="main-tittle mb-4">
                    <h2 className="mb-0">Image Gallery</h2>
                    <div className="d-flex">
                      <Buttons
                        type="button"
                        className=" ms-2"
                        onClick={() => {
                          modalHandleImg(false);
                        }}
                        content="Add New"
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <CommonTable
                      columns={columnsImg}
                      data={imageList}
                      pagination={true}
                      defaultSortField="imageGalleryId"
                      defaultSortAsc={true}
                      highlightOnHover
                      striped
                      dense
                      progressPending={ImgLoading}
                      selectableRowsHighlight
                      selectableRows={true}
                      selectableRowSelected={useCallback(
                        (row) => {
                          if (row && row.imageLink && imageLink) {
                            return row.imageLink === imageLink;
                          }
                          return false;
                        },
                        [imageLink]
                      )}
                      selectableRowDisabled={rowDisabledCriteria}
                      onChangePage={handlePageChangeImg}
                      onSelectedRowsChange={handleRowSelected}
                      paginationPerPage={pageSizeImg}
                      paginationTotalRows={totalPagesImg}
                      onChangeRowsPerPage={handleRowsPerPageChangeImg}
                      subHeader={true}
                      subHeaderComponent={
                        <>
                          <div>
                            <input
                              type="text"
                              placeholder="Search.... "
                              value={searchImg}
                              className="form-control"
                              onChange={(e) => setSearchImg(e.target.value)}
                              style={{ width: "200px", padding: "5px" }}
                            />
                          </div>
                        </>
                      }
                    />
                  </div>

                  <div className="col-12 text-end mt-2">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* import File*/}
        <Modal size="md" show={fileshow} onHide={handleCloseFile} centered>
          <Modal.Header closeButton>
            <Modal.Title>Import Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="file"
                      className="form-control p-2 mb-3 quform-element"
                      accept=".xlsx"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleCloseFile}
                      content="Cancel"
                    />
                    <Buttons
                      type="button"
                      onClick={handleFile}
                      content="Save"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Product Retailer Id :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.productRetailerId}</p>
                </div>
                <div className="col-md-4">
                  <p>Title :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.title}</p>
                </div>
                <div className="col-md-4">
                  <p>Description:</p>
                </div>
                <div className="col-md-8 text-break">
                  <p>{viewData?.description}</p>
                </div>
                <div className="col-md-4">
                  <p>Availability:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.availability}</p>
                </div>
                <div className="col-md-4">
                  <p>Condition:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.condition}</p>
                </div>
                <div className="col-md-4">
                  <p>Price:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.price}</p>
                </div>
                <div className="col-md-4">
                  <p>Link:</p>
                </div>
                <div className="col-md-8 text-break">
                  <p>{viewData?.link}</p>
                </div>
                <div className="col-md-4">
                  <p>Image Link:</p>
                </div>
                <div className="col-md-8 text-break">
                  <p>{viewData?.imageLink}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          confirmModel={deleteTemplateProduct}
        ></DeleteConfirmation>

        {/* Image Modal opens  */}

        {/* Add Edit Modal */}
        <Modal size="md" show={showImg} onHide={handleCloseImg} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add Image Gallery" : "Edit Image Gallery"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitFormImg}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Name"
                    >
                      <Inputtext
                        type="text"
                        label="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors?.name && (!name || spaceInput?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <FormLabel>Upload Image *</FormLabel>
                    {imageLinks === "" ? (
                      <div style={{ position: "relative" }}>
                        <input
                          className="form-control"
                          ref={imageInputRef}
                          accept="image/*"
                          name="imageLinks"
                          id="imageLinks"
                          type="file"
                          onChange={onChangeImage}
                        />
                      </div>
                    ) : (
                      <div className="imageContainer">
                        <img
                          src={isEdit && imgupload ? imageLinks : file}
                          width={130}
                          alt=""
                          height={120}
                          className="img-thumbnail image-input"
                        />
                        <button
                          onClick={() => {
                            setImageLinks("");
                            setFile(null);
                          }}
                        >
                          X
                        </button>
                      </div>
                    )}
                    {!imageLinks && errors.imageLinks ? (
                      <span className="error">{errors.imageLinks}</span>
                    ) : null}
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleCloseImg}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitImgLoading}
                      content={
                        submitImgLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal
          show={viewModalImg}
          onHide={handleCloseviewImg}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>View </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Comapny Name:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewDataImg?.name}</p>
                </div>
                <div className="col-md-4">
                  <p>Image :</p>
                </div>
                <div className="col-md-8">
                  <p>
                    <img
                      src={viewDataImg?.imageLink}
                      alt={""}
                      style={{ width: "80px", height: "80px" }}
                    />
                  </p>
                </div>
                <div className="col-md-4">
                  <p>Image Link:</p>
                </div>
                <div className="col-md-8 text-break">
                  <p>{viewDataImg?.imageLink}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModalImg}
          hideModal={handleCloseDeleteImg}
          confirmModel={deleteImageGallery}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default ProductTemplate;
