import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import { MdOutlineViewHeadline } from "react-icons/md";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import store from "../../redux/store/store";

const Order = () => {
  const navigate = useNavigate();
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setviewData] = useState("");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const loginUserDetail = store.getState().login.auth;
  const companyId = loginUserDetail?.companyId;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrderList();
  }, [page, pageSize, search]);
  
  const columns = [
    {
      name: "No",
      selector: (row) => row.orderId,
      // sortable: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => (row.mobileNo ? row.mobileNo : "-"),
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },
    {
      name: "Catalog Id",
      selector: (row) => (row.catalogId ? row.catalogId : "-"),
      // sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => (row.totalAmount ? row.totalAmount : "-"),
      // sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        if (row.createdAt) {
          const date = new Date(row.createdAt);
          return `${date.toLocaleDateString()}  ${date.toLocaleTimeString()}`;
        } else {
          return "-";
        }
      },

      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.orderId}>
          <div className="edit-btn me-2">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltips}
            >
              <button
                className="btn btn-none border-0"
                onClick={() => navigate(`/order/orderdetails/${d?.orderId}`)}
              >
                <MdOutlineViewHeadline className="text-black" />
              </button>
            </OverlayTrigger>
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewOrder(d?.orderId)} />
          </div>
        </div>,
      ],
    },
  ];

    /*-------------------- API CALL START HERE --------------------*/
  const getOrderList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Order +
          `/company/${companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false);
      setTotalPages(pageCount);
      setOrders(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const viewOrder = async (orderId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.Order + `/${orderId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const renderTooltips = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Order Details
    </Tooltip>
  );

  const handleCloseview = () => {
    setViewModal(false);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Orders</h2>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={orders}
            pagination={true}
            defaultSortField="orderId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            progressPending={loading}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>Mobile No. :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.mobileNo}</p>
                </div>
                <div className="col-md-5">
                  <p>Name :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.name || "-"}</p>
                </div>
                <div className="col-md-5">
                  <p>Catalog Id :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.catalogId}</p>
                </div>
                <div className="col-md-5">
                  <p>Total Amount :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.totalAmount || "-"}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Order;
