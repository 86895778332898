import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Buttons from "../button/buttons";

const DeleteConfirmation = ({ showModal, hideModal, confirmModel }) => {
  return (
    <Modal show={showModal} onHide={hideModal} className="deleteModal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-popup text-center">
          <AiOutlineCloseCircle className="close-icon" />
          <h2 className="mt-4 mb-4">Are you sure?</h2>
          <p>
            Do you really want to delete these records? This process cannot be
            undone.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Buttons className="me-3" onClick={hideModal} content="Cancel" />
        <Buttons
          onClick={confirmModel}
          content="Delete"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
