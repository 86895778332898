import * as Constants from "../common/baseurl";

/****************** API ERROR CODES ***************************/
export const CODE_SUCCESS = 200;
export const CODE_INTERNL_SERVER = 500;
export const CODE_ACCESS_TOKEN_UNAUTHORIZED =401;
export const CODE_INFO = 102;
export const NOT_FOUND = 404;
export const SOMTING_WRONG = 999;
export const EXPIRED_TOKEN = 403;

/****************** API ERROR CODES END HERE *******************/

/***************** API ENDPOINTS START HERE ********************/
export const WebSite = Constants.BASE_URL_API;
export const AuthRoutes = Constants.BASE_URL_API + "auth/";
/******************** API ENDPOINTS END HERE*********************/

/********************HOME PAGE API START HERE *******************/
export const SaveLogin = AuthRoutes + "login";
export const ForgotPassword = AuthRoutes + "forgot/password";
export const ChangePassword = AuthRoutes + "change/password";
export const VerifyOTP = AuthRoutes + "verify/otp";
export const GetContactUs = AuthRoutes + "contact/us";

export const Company = WebSite + "company";
export const Contact = WebSite + "contacts";
export const User = WebSite + "user";
export const Env = WebSite + "env";
export const ContactGroup = WebSite + "contacts/group/master";
export const MessageTemplate = WebSite + "message/template";
export const TemplateProduct = WebSite + "message/template";
export const ImageGallery = WebSite + "image/gallery";
export const SendMessage = WebSite + "send/message";
export const SendMessageDetail = WebSite + "message/detail";
export const Order = WebSite + "order";
export const OrderDetail = WebSite + "order/detail";

/********************HOME PAGE API END HERE *********************/
