import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import * as Constants from "../../common/constant";
import CommonApiServices from "../../common/apiServices";
import swal from "sweetalert";

const CompanyList = () => {
  const [id, setId] = useState();
  const [name, setCompanyName] = useState("");
  const [uniqueCode, setUniqueCode] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileno] = useState("");
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEdit, setisEdited] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [company, setCompanyDetail] = useState("");
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const spaceInput = /^\s*$/;
  const numberPattern = /^[0-9]{10}$/;

  useEffect(() => {
    getCompaniesList();
  }, [page, pageSize, search]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.companyId,
      // sortable: true,
    },
    {
      name: "Unique code",
      selector: (row) => {
        return row?.uniqueCode ? row?.uniqueCode : "-";
      },
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return row?.name ? row?.name : "-";
      },
      // sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row?.description ? row?.description : "-"),
      // sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (row?.email ? row?.email : "-"),
      // sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => (row?.mobileNo ? row?.mobileNo : "-"),
      // sortable: true,
    },

    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.companyId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewCompany(d.companyId)} />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d.companyId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  const clearData = () => {
    setCompanyName("");
    setEmail("");
    setMobileno("");
    setSubmitLoading(false);
    setDescription("");
    setUniqueCode("");
    setErrors({});
  };
  /*-------------------- API CALL START HERE --------------------*/
  const getCompaniesList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Company +
          `?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false)
      setTotalPages(pageCount);
      setCompanies(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      email,
      mobileNo,
      name,
      uniqueCode,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      const payload = {
        email: email,
        mobileNo: mobileNo,
        description: description,
        name: name,
        uniqueCode: uniqueCode,
      };
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.Company,
            payload
          );
          clearData();
          setId("");
          getCompaniesList();
          handleClose();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            Constants.Company + `/${id}`,
            payload
          );
          clearData();
          setId("");
          getCompaniesList();
          handleClose();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }

      // setErrors({});
    }
  };
  
  const viewCompany = async (companyId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.Company + `/${companyId}`
      );
      setCompanyDetail(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteCompany = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.Company + `/${id}`
      );
      clearData();
      getCompaniesList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/
  const modalHandleforEdit = (record, isEdit) => {
    setShow(true);
    setId(record.companyId);
    setisEdited(isEdit);
    setEmail(record.email);
    setMobileno(record?.mobileNo);
    setCompanyName(record?.name);
    setUniqueCode(record?.uniqueCode);
  };
  const handleClose = () => {
    clearData();
    setShow(false);
    setErrors({});
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };

  const handleCloseview = () => {
    setViewModal(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Company List</h2>
          <Buttons
            type="button"
            onClick={() => {
              modalHandle(false);
            }}
            content="Add New"
          />
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={companies}
            defaultSortField="companyId"
            pagination={true}
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            progressPending={loading}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>
        {/* Add Edit Modal */}
        <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add Company" : "Edit Company"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Company Name"
                    >
                      <Inputtext
                        type="text"
                        label="Company Name *"
                        value={name}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      {errors?.name && (!name || /^\s*$/?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Unique Code"
                    >
                      <Inputtext
                        type="text"
                        label="Unique Code *"
                        value={uniqueCode}
                        onChange={(e) => setUniqueCode(e.target.value)}
                      />
                      {errors?.uniqueCode &&
                      (!uniqueCode || /^\s*$/?.test(uniqueCode)) ? (
                        <span className="error">{errors?.uniqueCode}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Email"
                    >
                      <Inputtext
                        type="text"
                        label="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && !pattern?.test(email) ? (
                        <span className="error">{errors.email}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Mobile No"
                    >
                      <Inputtext
                        type="text"
                        label="Mobile No *"
                        value={mobileNo}
                        onChange={(e) => setMobileno(e.target.value)}
                      />
                      {errors?.mobileNo && !numberPattern?.test(mobileNo) ? (
                        <span className="error">{errors?.mobileNo}</span>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Description"
                    >
                      <Inputtext
                        type="text"
                        label="Description: "
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errors?.description &&
                      (!description || spaceInput?.test(description)) ? (
                        <span className="error">{errors?.description}</span>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Name :</p>
                </div>
                <div className="col-md-8">
                  <p>{company?.name}</p>
                </div>
                <div className="col-md-4">
                  <p>Email :</p>
                </div>
                <div className="col-md-8">
                  <p>{company?.email}</p>
                </div>
                <div className="col-md-4">
                  <p>Mobile No. :</p>
                </div>
                <div className="col-md-8">
                  <p>{company?.mobileNo}</p>
                </div>
                <div className="col-md-4">
                  <p>Unique Code :</p>
                </div>
                <div className="col-md-8">
                  <p>{company?.uniqueCode}</p>
                </div>
                <div className="col-md-4">
                  <p> Description :</p>
                </div>
                <div className="col-md-8">
                  <p>{company?.description}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          centered
          confirmModel={deleteCompany}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default CompanyList;
