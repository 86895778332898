import axios from "axios";
import * as Constants from "./constant";
import swal from "sweetalert";
import store from "../redux/store/store";

const CommonApiServices = {
  getData: async (url) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (response.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        response.status === Constants.CODE_INFO ||
        response.status === Constants.NOT_FOUND ||
        response.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        response.status === Constants.SOMTING_WRONG
      ) {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      } else if (response.status === Constants.CODE_INTERNL_SERVER) {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      if (
        error.response.status === Constants.CODE_INFO ||
        error.response.status === Constants.NOT_FOUND ||
        error.response.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        error.response.status === Constants.SOMTING_WRONG ||
        error.response.status === Constants.EXPIRED_TOKEN
      ) {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      } else if (error.response.status === Constants.CODE_INTERNL_SERVER) {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
      throw new Error(`${error.message}`);
    }
  },
  authPostData: async (url, payload) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        data.status === Constants.SOMTING_WRONG
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
  postData: async (url, payload) => {
    
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.SOMTING_WRONG
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
  putData: async (url, payload) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.put(url, payload, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.SOMTING_WRONG ||
        data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        data.status === Constants.EXPIRED_TOKEN
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
  deleteData: async (url) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        data.status === Constants.SOMTING_WRONG ||
        data.status === Constants.EXPIRED_TOKEN
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
  viewData: async (url) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(`${error.message}`);
    }
  },
  postForm: async (url, payload) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        data.status === Constants.SOMTING_WRONG
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
  putForm: async (url, payload) => {
    const token = store.getState().login.auth.token;
    try {
      const response = await axios.put(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      });
      const data = response.data;
      if (data.status === Constants.CODE_SUCCESS) {
        return data;
      } else if (
        data.status === Constants.CODE_INFO ||
        data.status === Constants.NOT_FOUND ||
        data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED ||
        data.status === Constants.SOMTING_WRONG ||
        data.status === Constants.EXPIRED_TOKEN
      ) {
        swal(`${data?.message}`, "", "warning");
      } else if (data.status === Constants.CODE_INTERNL_SERVER) {
        swal(`${data.error}`, "", "warning");
      } else {
        localStorage.removeItem("persist:root");
        window.location.href = "/login";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, "", "warning");
      throw new Error(`${error.message}`);
    }
  },
};

export default CommonApiServices;
