/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { Box, FormLabel } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DataTable from "react-data-table-component";

const ContactGroup = () => {
  const spaceInput = /^\s*$/;
  const companyId = useSelector((state) => state?.login?.auth?.companyId);
  const [contactGroup, setContactGroup] = useState([]);
  const [contactAllGroup, setContactAllGroup] = useState([]);
  const [allContacts, setAllContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [viewData, setviewData] = useState();
  const [selectedContactCount, setSelectedContactCount] = useState(0);
  const [viewtableData, setviewTableData] = useState([]);
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [pageAll, setPageAll] = useState(1);
  const [pageSizeAll, setPageSizeAll] = useState(10);
  const [totalPagesAll, setTotalPagesAll] = useState();
  const [pageview, setPageview] = useState(1);
  const [pageSizeView, setPageSizeView] = useState(10);
  const [totalPagesView, setTotalPagesView] = useState();
  const [searchView, setSearchView] = useState("");
  const [id, setId] = useState();
  const [deleteId, setdeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingContact, setLoadingContact] = useState(true);
  const [match, setMatch] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    getContactGroupData();
  }, [page, search, pageSize]);

  useEffect(() => {
    getContactGroupAllData();
  }, [pageAll, pageSizeAll, searchText, pageview]);

  const clearData = () => {
    setName("");
    setPageAll(1);
    setSubmitLoading(false);
    setErrors("");
    setAllContacts(false);
    setMatch([]);
  };

  const columns = [
    {
      name: "No",
      selector: (row) => row.contactGroupMasterId,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },

    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.contactGroupMasterId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView
              onClick={() => {
                setViewModal(true);
                viewContactData(d?.contactGroupMasterId);
              }}
            />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setdeleteId(d?.contactGroupMasterId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  const column = [
    {
      name: "Mobile No",
      selector: (row) => (row.name ? row.mobileNo : "-"),
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },
  ];

  const subColumn = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Mobile No",
      field: "mobileNo",
      // sortable: true,
    },
  ];
  /*-------------------- API CALL START HERE --------------------*/

  const getContactGroupData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.ContactGroup}/company/${companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      setContactGroup(response.data);
      setLoadingContact(false);
      setTotalPages(response?.count);
    } catch (error) {
      setLoadingContact(false);
      console.error(error.message);
    }
  };

  const getContactGroupAllData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.Contact}/company/${companyId}?page=${pageAll}&pageSize=${pageSizeAll}&search=${searchText}`
      );
      setLoading(false);
      setContactAllGroup(response.data);
      setTotalPagesAll(response?.count);
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const body = {
      name: name,
      companyId: companyId,
    };
    if (allContacts) {
      body["allContacts"] = allContacts;
    } else if (contacts?.length) {
      body["contacts"] = contacts;
    } else {
      body["allContacts"] = allContacts;
    }

    const validationErrors = Validation({
      name,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.ContactGroup,
            body
          );
          clearData();
          setSubmitLoading(false);
          getContactGroupData();
          setId("");
          setName("");
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            `  ${Constants.ContactGroup}/${id}/company/${companyId}`,
            body
          );
          clearData();
          getContactGroupData();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }
      setErrors({});
    }
  };
  const deleteContactGroup = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        `  ${Constants.ContactGroup}/${deleteId}/company/${companyId}`
      );
      getContactGroupData();
      setdeleteId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };
  const viewContactData = async (viewId) => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.ContactGroup}/${viewId}/company/${companyId}`
      );
      setviewData(response.data);
      const record = response.data;

      const mobileNumbers = Array.isArray(record?.contacts)
        ? record?.contacts?.map((contact) => contact.contactDetail)
        : [];

      setSelectedContactCount(mobileNumbers?.length);

      setviewTableData(mobileNumbers);
      setTotalPagesView(mobileNumbers?.length);
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/

  const modalHandleforEdit = (record, isEdit) => {
    setName(record.name);
    setId(record?.contactGroupMasterId);
    setisEdited(isEdit);
    const mobileNumbers = Array.isArray(record?.contacts)
      ? record?.contacts?.map((contact) => contact.mobileNo)
      : [];
    setShow(true);

    setMatch(mobileNumbers);
  };
  const handleChangeAllContacts = (e) => {
    setAllContacts(e.target.checked);
    setContacts([]);
  };
  const handleClose = () => {
    setPageAll(1);
    setMatch([]);
    setShow(false);
    setErrors({});
    setName("");
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPageAll(newPaginationModel.page + 1);
    setPageSizeAll(newPaginationModel.pageSize);
  };

  const handleCloseview = () => {
    setViewModal(false);
    clearData();
    setviewTableData([]);
    setName("");
  };

  const filterviewDataTable = viewtableData?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchView.toLowerCase()) ||
      item.mobileNo.includes(searchView)
  );
  const customStyles = {
    header: {
      style: {
        minHeight: "70px",
      },
    },
    headRow: {
      style: {
        paddingTop: "10px",
        paddingBottom: "10px",
        borderTopStyle: "groove",
        borderTopWidth: "1px",
        fontWeight: "700",
        fontSize: "14px",
      },
    },
    subHeader: {
      style: {
        backgroundColor: "#f0f0f0",
        padding: "10px",
        borderBottomStyle: "solid rgb(224 224 224)",
        borderBottomWidth: "1px",
        borderTopStyle: "solid rgb(224 224 224)", // Add top border
        borderTopWidth: "1px",
        // marginBottom: "2px",
      },
    },
    subHeaderInput: {
      style: {
        width: "200px",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid #ccc",
      },
    },
  };

  const handleRowSelectionModelChange = (rows) => {
    setMatch(rows);
    setContacts(rows.map((row) => row));
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">ContactGroup</h2>
          <Buttons
            type="button"
            onClick={() => {
              modalHandle(false);
            }}
            content="Add New"
          />
        </div>
        <div className="">
          <CommonTable
            columns={columns}
            data={contactGroup}
            pagination={true}
            defaultSortField="id"
            defaultSortAsc={true}
            subHeader={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            progressPending={loadingContact}
            noHeader={!contactGroup.length}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* Add Edit Modal */}
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit" : "Add"} Contact Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="name"
                    >
                      <Inputtext
                        type="text"
                        label="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors?.name && (!name || spaceInput?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <FormLabel className="form-label">
                      All Contact :
                      <input
                        className="form-check-input ms-2 me-2 "
                        style={{
                          width: "20px",
                          height: "20px",
                          borderColor: "#0000004f",
                        }}
                        type="checkbox"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        disabled={match.length !== 0}
                        checked={allContacts}
                        onChange={(e) => handleChangeAllContacts(e)}
                      />
                    </FormLabel>
                  </div>
                  <div className="col-md-12 mb-4 ">
                    <div className="d-flex justify-content-end mb-3">
                      <input
                        type="text"
                        placeholder="Search.... "
                        value={searchText}
                        className="form-control"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: "200px", padding: "5px" }}
                      />
                    </div>
                    <div style={{ height: "auto", width: "100%" }} className="">
                      <DataGrid
                        slots={{
                          noRowsOverlay: (
                            <Box sx={{ mt: 1 }}>No Data found.</Box>
                          ),
                        }}
                        columns={[...subColumn]}
                        disableColumnMenu={true}
                        sx={{
                          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                            {
                              display: "none",
                            },
                        }}
                        getRowId={(row) => row.mobileNo}
                        rows={contactAllGroup}
                        isRowSelectable={(row) => !allContacts}
                        rowCount={totalPagesAll}
                        onPaginationModelChange={handlePaginationModelChange}
                        checkboxSelection
                        keepNonExistentRowsSelected
                        rowSelectionModel={match}
                        onRowSelectionModelChange={
                          handleRowSelectionModelChange
                        }
                        pageSizeOptions={[5, 10, 50]}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              page: pageAll - 1,
                              pageSize: pageSizeAll,
                            },
                          },
                        }}
                        paginationMode="server"
                        selectionModel={match}
                        {...contactAllGroup}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className=" me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Name : </p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.name}</p>
                </div>
                <div className="col-md-4">
                  <p>Total Contact : </p>
                </div>
                <div className="col-md-8">
                  <p>{selectedContactCount}</p>
                </div>

                <div className="col-md-4">
                  <FormLabel className="form-label">All Contact * </FormLabel>
                </div>
                <div className="col-md-12 mb-4 ">
                  <div style={{ height: "auto", width: "100%" }} className="">
                    <DataTable
                      pagination={true}
                      columns={column}
                      customStyles={customStyles}
                      progressPending={loading}
                      data={filterviewDataTable}
                      defaultSortField="mobileNo"
                      defaultSortAsc={true}
                      selectableRows={false}
                      paginator
                      paginationPerPage={pageSizeView}
                      noHeader={!viewtableData.length}
                      subHeader={true}
                      subHeaderComponent={
                        <input
                          type="text"
                          placeholder="Search Title"
                          value={searchView}
                          className="form-control"
                          onChange={(e) => setSearchView(e.target.value)}
                          style={{ width: "200px", padding: "5px" }}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          confirmModel={deleteContactGroup}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default ContactGroup;
