/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../Sidebar/sidebar.css";
import { FaBars, FaRegUser } from "react-icons/fa";
import { TfiGallery } from "react-icons/tfi";
import { AiOutlineFlag, AiOutlineRedEnvelope } from "react-icons/ai";
import { MdPayments } from "react-icons/md";
import { AiFillDashboard } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import store from "../../redux/store/store";
import { logout } from "../../redux/slice/loginSlice";
import { FiBox } from "react-icons/fi";
import { useDispatch } from "react-redux";

export default function Sidebar({ toggel, Settoggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggel1, Settoggle1] = useState(true);
  const [toggel2, Settoggle2] = useState(true);
  const [toggel3, Settoggle3] = useState(true);
  const dispatch = useDispatch();

  const loginUserDetail = store.getState().login.auth;

  const user = loginUserDetail?.isSuperAdmin;

  let sidebar = document.querySelector(".sidebar");
  let sidebarBtn = document.querySelector(".bx-menu");
  sidebarBtn?.addEventListener("click", () => {
    sidebar?.classList.toggle("close");
  });

  const logOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div className={toggel ? "sidebar" : "sidebar close"}>
        <div className="logo-details">
          {/* <span className="logo_name text-center">
        <img src='/assets/images/job-logo.png' className='img-fluid w-75 m-auto'></img>
      </span> */}
        </div>
        <ul className="nav-links pt-0">
          {user ? (
            <>
              <li className={`${location.pathname == "/" ? "active" : ""}`}>
                <Link to="/">
                  <AiFillDashboard className="bx bx-grid-alt" />
                  <span className="link_name">Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname == "/companylist" ? "active" : ""
                }`}
              >
                <Link to="companylist">
                  <FaRegBuilding className="bx" />
                  <span className="link_name">Company List</span>
                </Link>
              </li>
              <li
                className={`${location.pathname == "/users" ? "active" : ""}`}
              >
                <Link to="users">
                  <FaRegUser className="bx" />
                  <span className="link_name">Users</span>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={`${location.pathname == "/" ? "active" : ""}`}>
                <Link to="/">
                  <AiFillDashboard className="bx bx-grid-alt" />
                  <span className="link_name">Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname == "/companyenv" ? "active" : ""
                }`}
              >
                <Link to="/companyenv">
                  <AiOutlineRedEnvelope className="bx" />
                  <span className="link_name">Company Env</span>
                </Link>
              </li>

              <li
                className={toggel1 ? "" : "active showMenu"}
                onClick={() => {
                  Settoggle3(true);
                  Settoggle2(true);
                }}
              >
                <div className="iocn-link">
                  <a href="#">
                    <AiOutlineFlag className="bx" />
                    <span className="link_name">Message</span>
                  </a>
                  <FiChevronDown
                    className="bx bxs-chevron-down arrow"
                    onClick={() => {
                      toggel1 ? Settoggle1(false) : Settoggle1(true);
                    }}
                  />
                </div>
                <ul className="sub-menu">
                  <li>
                    <Link
                      to="/messagetemplate"
                      onClick={() => navigate("/messagetemplate")}
                    >
                      Message Template
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sendmessage"
                      onClick={() => navigate("/sendmessage")}
                    >
                      Send Message
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={toggel2 ? "" : "active showMenu"}
                onClick={() => {
                  Settoggle3(true);
                  Settoggle1(true);
                }}
              >
                <div className="iocn-link">
                  <a href="#">
                    <MdPayments className="bx" />
                    <span className="link_name">Contact</span>
                  </a>
                  <FiChevronDown
                    className="bx bxs-chevron-down arrow"
                    onClick={() => {
                      toggel2 ? Settoggle2(false) : Settoggle2(true);
                    }}
                  />
                </div>
                <ul className="sub-menu">
                  <li>
                    <Link to="/contact" onClick={() => navigate("/contact")}>
                      ContactList
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contactgroup"
                      onClick={() => navigate("/contactgroup")}
                    >
                      ContactGroup
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`${
                  location.pathname == "/imagegallery" ? "active" : ""
                }`}
              >
                <Link to="/imagegallery">
                  <TfiGallery className="bx" />
                  <span className="link_name">Image Gallery</span>
                </Link>
              </li>
              <li
                className={`${location.pathname == "/order" ? "active" : ""}`}
              >
                <Link to="/order">
                  <FiBox className="bx" />
                  <span className="link_name">Order</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <section className="home-section">
        <div className="home-content">
          <FaBars
            className="bx bx-menu position-absolute"
            onClick={() => {
              toggel ? Settoggle(false) : Settoggle(true);
            }}
          />
          <div className="header d-inline-flex  p-3  text-end align-items-baseline justify-content-end w-100">
            <span className="text-end me-2 text-nowrap">{`${loginUserDetail?.firstName} ${loginUserDetail?.lastName}`}</span>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <AiOutlineUser />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
                {/* <Dropdown.Item href="/changepassword">
                  Change Password
                </Dropdown.Item> */}
                <Dropdown.Item href="/updatepassword">
                  Update Password
                </Dropdown.Item>
                <Dropdown.Item onClick={logOut}>
                  {/* <MdOutlineLogout className="bx bx-grid-alt me-2" /> */}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </section>
    </>
  );
}
