import React from 'react'
import { TextField } from '@mui/material'
import '../InputsText/inputstyle.css'
export default function Inputtext(props) {
  return (
    
    <div className='input-text-box'>
      <TextField id="outlined-basic" type={props.type} label={props.label} variant="outlined" onChange={props?.onChange} value={props.value} />
    </div>
  )
}
