import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import * as XLSX from "xlsx";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import { InputLabel } from "@mui/material";

const Contactus = () => {
  const companyId = useSelector((state) => state?.login?.auth?.companyId);
  const [contact, setContact] = useState([]);
  const [viewData, setviewData] = useState();
  const [name, setName] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [id, setId] = useState();
  const [deleteId, setdeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [fileshow, setFileShow] = useState(false);
  const spaceInput = /^\s*$/;
  const numberPattern = /^[0-9]{10}$/;
  const [isEdit, setisEdited] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingContact, setLoadingContact] = useState(true);

  useEffect(() => {
    getContactData();
  }, [page, search, pageSize]);

  const columns = [
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => (row.mobileNo ? row.mobileNo : "-"),
      // sortable: true,
    },
    {
      name: "Location",
      selector: (row) => (row.location ? row.location : "-"),
      // sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row.address ? row.address : "-"),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.id}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView
              onClick={() => {
                setViewModal(true);
                viewContactData(d?.mobileNo);
              }}
            />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setdeleteId(d?.mobileNo);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  /*-------------------- API CALL START HERE --------------------*/
  const clearData = () => {
    setName("");
    setMobileno("");
    setAddress("");
    setSubmitLoading(false);
    setLocation("");
    setErrors("");
  };
  const getContactData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.Contact}/company/${companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      setLoadingContact(false);
      setContact(response.data);
      setTotalPages(response?.count);
    } catch (error) {
      console.error(error.message);
    }
  };
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const body = {
      name: name,
      companyId: companyId,
      mobileNo: mobileno,
      address: address,
      location: location,
    };
    const validationErrors = Validation({
      name,
      mobileno,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.Contact,
            body
          );
          clearData();
          getContactData();
          setId("");
          setShow(false);
          setSubmitLoading(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          setSubmitLoading(false);
          console.error(error.message);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            `  ${Constants.Contact}/${id}/company/${companyId}`,
            body
          );
          clearData();
          getContactData();
          setShow(false);
          setSubmitLoading(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }
      setErrors({});
    }
  };
  const deleteContact = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        `  ${Constants.Contact}/${deleteId}/company/${companyId}`
      );
      getContactData();
      setdeleteId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };
  const viewContactData = async (viewId) => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.Contact}/${viewId}/company/${companyId}`
      );
      setviewData(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  /* export Excel File */
  const exportData = async () => {
    try {
      const response = await CommonApiServices.getData(
        `${Constants.Contact}/all/company/${companyId}`
      );
      const filteredData = response.data.map((item) => ({
        mobileNo: item.mobileNo,
        companyId: item.companyId,
        name: item.name,
        address: item.address,
        location: item.location,
        status: item.status,
      }));
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "ContactData.xlsx");
      setLoading(false);
      swal(`${response.message}`, "", "success");
    } catch (error) {
      console.error(error.message);
    }
  };

  /* Import Excel File*/
  const handleFile = async () => {
    if (!file.name.endsWith(".xlsx")) {
      swal("Invalid file format. Please upload an Excel file.", "", "warning");
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const columnHeaders = ["mobileNo", "name", "location", "address"];
        const headersInSheet = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
        const isValidFile = columnHeaders.every((header) =>
          headersInSheet.includes(header)
        );
        if (!isValidFile) {
          swal(
            "Invalid file format. Please ensure the file contains columns: mobileNo, name, location,address",
            "",
            "warning"
          );
          return;
        }
        const excelData = XLSX.utils.sheet_to_json(ws);
        const filteredData = excelData?.every(
          (item) => item.companyId == companyId
        );
        if (!filteredData) {
          swal("No data matching the specified Company Id.", "", "warning");
          return;
        }
        const formattedData = excelData?.map((item) => ({
          mobileNo: item?.mobileNo,
          name: item?.name,
          companyId: item?.companyId,
          location: item?.location,
          address: item?.address,
        }));
        const response = await CommonApiServices.authPostData(
          `${Constants.Contact}/upload/multiple`,
          { contacts: formattedData }
        );
        swal(`${response.message}`, "", "success");
        setFile("");
        getContactData();
        setFileShow(false);
      } catch (error) {
        console.error("Error parsing the Excel file:", error);
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/

  const modalHandleforEdit = (record, isEdit) => {
    setShow(true);
    setId(record?.mobileNo);
    setMobileno(record?.mobileNo);
    setAddress(record?.address);
    setLocation(record?.location);
    setName(record?.name);
    setisEdited(isEdit);
  };
  const handleClose = () => {
    setShow(false);
    setErrors({});
    clearData();
  };
  const handlePhoneChange = (value) => {
    setMobileno(value);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };
  const modalFileHandle = async () => {
    setFileShow(true);
  };

  const handleCloseview = () => {
    setViewModal(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  // Import Excel
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const handleCloseFile = () => {
    setFileShow(false);
    setFile("");
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Contact List</h2>
          <div className="d-flex">
            <Buttons
              type="button"
              onClick={() => {
                modalFileHandle(false);
              }}
              content="Import"
            />
            <Buttons
              type="button"
              className=" ms-2"
              onClick={exportData}
              disabled={loading}
              content={loading ? "Exporting..." : "Export"}
            />
            <Buttons
              type="button"
              className=" ms-2"
              onClick={() => {
                modalHandle(false);
              }}
              content="Add New"
            />
          </div>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            pagination={true}
            data={contact}
            defaultSortField="mobileNo"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            onChangeRowsPerPage={handleRowsPerPageChange}
            progressPending={loadingContact}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>
        {/* Add Edit Modal */}
        <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add Contact" : "Edit Contact"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="name"
                    >
                      <Inputtext
                        type="text"
                        label="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors?.name && (!name || spaceInput?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <InputLabel>Mobile No.</InputLabel>
                    <PhoneInput
                      className="mt-2"
                      country={"in"}
                      value={mobileno}
                      onChange={handlePhoneChange}
                    />
                    {errors?.mobileno && !numberPattern?.test(mobileno) ? (
                      <span className="error">{errors?.mobileno}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="location"
                    >
                      <Inputtext
                        type="text"
                        label="Location "
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="address"
                    >
                      <Inputtext
                        type="text"
                        label="Address "
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : !isEdit ? (
                          "Save"
                        ) : (
                          "Update"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* import File*/}
        <Modal size="md" show={fileshow} onHide={handleCloseFile} centered>
          <Modal.Header closeButton>
            <Modal.Title>Import Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="file"
                      className="form-control p-2 mb-3 quform-element"
                      id="file"
                      accept=".xlsx"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleCloseFile}
                      content="Cancel"
                    />
                    <Buttons
                      type="button"
                      onClick={handleFile}
                      content="Save"
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Name :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.name}</p>
                </div>
                <div className="col-md-4">
                  <p>Mobile No. :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.mobileNo}</p>
                </div>
                <div className="col-md-4">
                  <p> Location :</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.location ? viewData?.location : "-"}</p>
                </div>
                <div className="col-md-4">
                  <p>Address:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.address ? viewData?.address : "-"}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          confirmModel={deleteContact}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default Contactus;
