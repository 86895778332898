import React from "react";


const Buttons = (props) => {
  return (
    <button className={`btn btn-warning ${props.className} `} onClick={props.onClick} type={props.type} disabled={props?.disabled}>
      {props.content}
    </button>
  )
}
export default Buttons

