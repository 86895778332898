import React, { useState } from "react";
import { HiOutlineUser } from "react-icons/hi2";
import Validation from "../../common/validation";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../common/constant";
import CommonApiServices from "../../common/apiServices";
import { Spinner } from "react-bootstrap";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

    /*-------------------- API CALL START HERE --------------------*/
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({ email });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      try {
        const resJson = await CommonApiServices.postData(
          Constants.ForgotPassword,
          {
            email: email,
          }
        );
        if (resJson?.status === Constants.CODE_SUCCESS) {
          navigate("/verificationcode", { state: { user: resJson?.data } });
          setSubmitLoading(false);
        } else {
          setEmail("");
          setSubmitLoading(false);
          setErrors({})
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*-------------------- API CALL END HERE --------------------*/
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-details text-center p-5">
              <div>
                <h1>
                  whatsapp
                  <br /> database
                </h1>
                <img
                  src="/assets/img/forgot3.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-form-box p-5">
              <div className="log-form-box p-5">
                <div className="login-form w-100">
                  <h2>Forgot Password</h2>
                  {/* <p>user</p> */}
                  <form className="log-form mt-3" onSubmit={onSubmitForm}>
                    <div className="mb-4 position-relative">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        email id
                      </label>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        id="exampleInputEmail1"
                      />
                      <HiOutlineUser
                        style={{ color: " #051D34" }}
                        className="img-fluid login-icon"
                      />
                      {errors.email && !pattern?.test(email) ? (
                        <span className="error">{errors.email}</span>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      disabled={submitLoading}
                      className="btn btn-primary w-100 mt-3 btn-primary "
                    >
                      {submitLoading ? <Spinner animation="border" size="sm" /> : "Forgot Password"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
