import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";
import store from "../../redux/store/store";
import { imageFormat, imageMessage } from "../../common/uploadImage";
import { FormLabel } from "@mui/material";

const ImageGallery = () => {
  const loginUserDetail = store.getState().login.auth;
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setviewData] = useState("");
  const imageInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const spaceInput = /^\s*$/;
  const [imageGallery, setImageGallery] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const [imgupload, setImgupload] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getImageList();
  }, [page, pageSize, search]);

  const clearData = () => {
    setName("");
    setSubmitLoading(false);
    setImageLink("");
    if (imageInputRef.current) imageInputRef.current.value = "";
    setErrors({});
    setFile("");
  };

  const columns = [
    {
      name: "No",
      selector: (row) => row.imageGalleryId,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      // sortable: true,
    },
    {
      name: "Image",
      cell: (d) => (
        <img
          src={d.imageLink}
          alt={d.title}
          className="mb-2 mt-2"
          style={{ width: "80px", height: "80px" }}
        />
      ),
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },
    {
      name: "Image Link",
      selector: (row) => (row.imageLink ? row.imageLink : "-"),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.imageGalleryId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewImageGallery(d?.imageGalleryId)} />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d?.imageGalleryId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  /*-------------------- API CALL START HERE --------------------*/
  const getImageList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.ImageGallery +
          `/company/${loginUserDetail?.companyId}?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false);
      setTotalPages(pageCount);
      setImageGallery(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", imageLink);
    formData.append("companyId", loginUserDetail?.companyId);
    const validationErrors = Validation({
      name,
      imageLink,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      if (!isEdit) {
        try {
          const response = await CommonApiServices.postForm(
            Constants.ImageGallery,
            formData
          );
          clearData();
          setId("");
          getImageList();
          handleClose();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putForm(
            Constants.ImageGallery +
              `/${id}/company/${loginUserDetail?.companyId}`,
            formData
          );
          clearData();
          setId("");
          getImageList();
          handleClose();
          setSubmitLoading(false);
          setShow(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          setSubmitLoading(false);
          console.error(error.message);
        }
      }
      // setErrors({});
    }
  };

  const viewImageGallery = async (id) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.ImageGallery + `/${id}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteImageGallery = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.ImageGallery + `/${id}`
      );
      clearData();
      getImageList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };

  /*-------------------- API CALL END HERE --------------------*/
  const modalHandleforEdit = (record, isEdit) => {
    setShow(true);
    setId(record.imageGalleryId);
    setisEdited(isEdit);
    setName(record?.name);
    setImageLink(record?.imageLink);
    setFile(record?.imageLink);
  };

  const handleClose = () => {
    clearData();
    setShow(false);
    setErrors({});
  };
  // imageupload
  const onChangeImage = (event) => {
    const selectedFile = event.target.files[0];
    if (!event.target.files[0]?.name?.match(imageFormat)) {
      setImageLink("");
      setImgupload(false);
      if (imageInputRef.current) imageInputRef.current.value = "";
      swal(imageMessage, "", "warning");
      return false;
    }
    const formData = new FormData();
    formData.append("imageLink", event.currentTarget.files[0]);
    setImageLink(selectedFile);
    setImgupload(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const file = event.target.files[0];
        const width = img.width;
        const height = img.height;
        const minWidth = 500;
        const minHeight = 500;
        const maxSizeInBytes = 8 * 1024 * 1024; // 8 MB
        const recommendedWidth = 1200;
        const recommendedHeight = 628;

        if (file.size > maxSizeInBytes) {
          swal(
            "File Too Large",
            "Please upload images with a maximum size of 8 MB.",
            "error"
          );
          return;
        }

        if (width < minWidth || height < minHeight) {
          setImageLink("");
          setFile(null);
          if (imageInputRef.current) imageInputRef.current.value = "";
          swal(
            `Minimum image size should be ${minWidth} x ${minHeight} pixels.`,
            "",
            "warning"
          );
        }
        // else if (width > recommendedWidth || height > recommendedHeight) {
        //   swal(
        //     `We recommend using an image size of ${recommendedWidth} x ${recommendedHeight} pixels for best quality.`,
        //     "",
        //     "info"
        //   );
        // }
        else {
          setFile(URL.createObjectURL(event.target.files[0]));
        }
      };
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };

  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };

  const handleCloseview = () => {
    setViewModal(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Image Gallery</h2>
          <div className="d-flex">
            <Buttons
              type="button"
              className=" ms-2"
              onClick={() => {
                modalHandle(false);
              }}
              content="Add New"
            />
          </div>
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={imageGallery}
            pagination={true}
            defaultSortField="id"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            progressPending={loading}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>
        {/* Add Edit Modal */}
        <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEdit ? "Add Image Gallery" : "Edit Image Gallery"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Name"
                    >
                      <Inputtext
                        type="text"
                        label="Name *"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors?.name && (!name || spaceInput?.test(name)) ? (
                        <span className="error">{errors?.name}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <FormLabel>Upload Image *</FormLabel>
                    {imageLink === "" ? (
                      <div style={{ position: "relative" }}>
                        <input
                          className="form-control"
                          ref={imageInputRef}
                          accept="image/*"
                          name="imageLink"
                          id="imageLink "
                          type="file"
                          onChange={onChangeImage}
                        />
                      </div>
                    ) : (
                      <div className="imageContainer">
                        <img
                          src={isEdit && imgupload ? imageLink : file}
                          width={130}
                          alt=""
                          height={120}
                          className="img-thumbnail image-input"
                        />
                        <button
                          onClick={() => {
                            setImageLink("");
                            setFile(null);
                          }}
                        >
                          X
                        </button>
                      </div>
                    )}
                    {!imageLink && errors.imageLink ? (
                      <span className="error">{errors.imageLink}</span>
                    ) : null}
                  </div>
                  {/* <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Image Link"
                    >
                      <Inputtext
                        type="text"
                        label="Image link *"
                        value={img.replace("blob:", "")}
                      />
                      {errors?.imageLink &&
                      (!imageLink || spaceInput?.test(imageLink)) ? (
                        <span className="error">{errors?.imageLink}</span>
                      ) : null}
                    </Form.Group>
                  </div> */}
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />

                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-4">
                  <p>Comapny Name:</p>
                </div>
                <div className="col-md-8">
                  <p>{viewData?.name}</p>
                </div>
                <div className="col-md-4">
                  <p>Image :</p>
                </div>
                <div className="col-md-8">
                  <p>
                    <img
                      src={viewData?.imageLink}
                      alt={""}
                      style={{ width: "80px", height: "80px" }}
                    />
                  </p>
                </div>
                <div className="col-md-4">
                  <p>Image Link:</p>
                </div>
                <div className="col-md-8 text-break">
                  <p>{viewData?.imageLink}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          confirmModel={deleteImageGallery}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default ImageGallery;
