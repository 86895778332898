import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import Inputtext from "../../components/InputsText/inputtext";
import DeleteConfirmation from "../../components/model/deleteModel";
import CommonTable from "../../components/table/commonTable";
import Buttons from "../../components/button/buttons";
import Validation from "../../common/validation";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";

const Users = () => {
  const [id, setId] = useState();
  const [companyId, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileno] = useState("");
  const [show, setShow] = useState(false);
  const [isEdit, setisEdited] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [viewData, setviewData] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState("");
  const spaceInput = /^\s*$/;
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const numberPattern = /^[0-9]{10}$/;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsersList();
    getCompanies();
  }, [page, pageSize, search]);

  const columns = [
    {
      name: "No",
      selector: (row) => row.userId,
      // sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => {
        return row?.firstName;
      },
      // sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
      // sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      // sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row?.mobileNo,
      // sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row?.company?.name,
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "200px",
      cell: (d) => [
        <div className="action-btns d-flex p-2" key={d?.userId}>
          <div className="edit-btn me-2">
            <AiOutlineEdit
              onClick={() => {
                modalHandleforEdit(d, true);
                setShow(true);
              }}
            />
          </div>
          <div className="view-btn me-2">
            <GrFormView onClick={() => viewUser(d?.userId)} />
          </div>
          <div className="delete-btn me-2">
            <AiFillDelete
              onClick={() => {
                setId(d.userId);
                setDeleteModal(true);
              }}
            />
          </div>
        </div>,
      ],
    },
  ];

  /*-------------------- API CALL START HERE --------------------*/
  const getCompanies = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Company + "/all"
      );
      setCompanies(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getUsersList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.User + `?page=${page}&pageSize=${pageSize}&search=${search}`
      );
      const pageCount = response?.count;
      setLoading(false);
      setTotalPages(pageCount);
      setUsers(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      firstName,
      lastName,
      email,
      mobileNo,
      companyId,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      const payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileNo: mobileNo,
        companyId: companyId,
      };
      if (!isEdit) {
        try {
          const response = await CommonApiServices.authPostData(
            Constants.User,
            payload
          );
          clearData();
          setId("");
          getUsersList();
          handleClose();
          setShow(false);
          setSubmitLoading(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      } else {
        try {
          const response = await CommonApiServices.putData(
            Constants.User + `/${id}`,
            payload
          );
          clearData();
          setId("");
          getUsersList();
          handleClose();
          setShow(false);
          setSubmitLoading(false);
          swal(`${response.message}`, "", "success");
          return response;
        } catch (error) {
          console.error(error.message);
          setSubmitLoading(false);
        }
      }
      // setErrors({});
    }
  };
  const viewUser = async (userId) => {
    try {
      setViewModal(true);
      const response = await CommonApiServices.getData(
        Constants.User + `/${userId}`
      );
      setviewData(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await CommonApiServices.deleteData(
        Constants.User + `/${id}`
      );
      clearData();
      getUsersList();
      setId("");
      setDeleteModal(false);
      swal(`${response.message}`, "", "success");
      return response;
    } catch (error) {
      console.error(error.message);
    }
  };
  /*-------------------- API CALL END HERE --------------------*/
  const clearData = () => {
    setCompany("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobileno("");
    setErrors({});
  };

  const modalHandleforEdit = (record, isEdit) => {
    setShow(true);
    setId(record.userId);
    setisEdited(isEdit);
    setFirstName(record?.firstName);
    setLastName(record?.lastName);
    setEmail(record.email);
    setMobileno(record?.mobileNo);
    setCompany(record?.companyId);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const modalHandle = async (isEdit) => {
    setisEdited(isEdit);
    setShow(true);
  };
  const handleCloseview = () => {
    setViewModal(false);
  };
  const handleClose = () => {
    clearData();
    setShow(false);
    setErrors({});
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">Users</h2>
          <Buttons
            type="button"
            onClick={() => {
              modalHandle(false);
            }}
            content="Add New"
          />
        </div>
        <div className="table-responsive">
          <CommonTable
            columns={columns}
            data={users}
            pagination={true}
            defaultSortField="userId"
            defaultSortAsc={true}
            onChangePage={handlePageChange}
            paginationPerPage={pageSize}
            paginationTotalRows={totalPages}
            progressPending={loading}
            onChangeRowsPerPage={handleRowsPerPageChange}
            subHeader={true}
            subHeaderComponent={
              <>
                <div>
                  <input
                    type="text"
                    placeholder="Search.... "
                    value={search}
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "200px", padding: "5px" }}
                  />
                </div>
              </>
            }
          />
        </div>

        {/* Add Edit Modal */}
        <Modal size="md" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{!isEdit ? "Add Users" : "Edit Users"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={onSubmitForm}>
                <div className="row">
                  <div className="col-md-12">
                    <FormControl fullWidth className="mb-3">
                      <InputLabel id="demo-simple-select-label">
                        Company Name *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={companyId}
                        label="Company Name"
                        onChange={(e) => setCompany(e.target.value)}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        {companies?.map((comp) => {
                          return (
                            <MenuItem value={comp?.companyId}>
                              {comp?.name}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                      </Select>
                      {errors?.companyname &&
                      (!companyId || spaceInput?.test(companyId)) ? (
                        <span className="error">{errors?.companyname}</span>
                      ) : null}
                    </FormControl>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="First Name"
                    >
                      <Inputtext
                        type="text"
                        label="First Name *"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {errors?.firstName &&
                      (!firstName || spaceInput?.test(firstName)) ? (
                        <span className="error">{errors?.firstName}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Last Name"
                    >
                      <Inputtext
                        type="text"
                        label="Last Name *"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {errors?.lastName &&
                      (!lastName || spaceInput?.test(lastName)) ? (
                        <span className="error">{errors?.lastName}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Email"
                    >
                      <Inputtext
                        type="text"
                        label="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && !pattern?.test(email) ? (
                        <span className="error">{errors.email}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element "
                      controlId="Mobile No"
                    >
                      <Inputtext
                        type="text"
                        label="Mobile No *"
                        value={mobileNo}
                        onChange={(e) => setMobileno(e.target.value)}
                      />
                      {errors?.mobileNo && !numberPattern?.test(mobileNo) ? (
                        <span className="error">{errors?.mobileNo}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">
                    <Buttons
                      type="button"
                      className="me-3"
                      onClick={handleClose}
                      content="Cancel"
                    />
                    <Buttons
                      type="submit"
                      disabled={submitLoading}
                      content={
                        submitLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Modal */}
        <Modal show={viewModal} onHide={handleCloseview} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="view-popup">
              <div className="row">
                <div className="col-md-5">
                  <p>User Name :</p>
                </div>
                <div className="col-md-7">
                  <p>{`${viewData?.firstName}  ${viewData?.lastName}`}</p>
                </div>
                <div className="col-md-5">
                  <p>Email :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.email}</p>
                </div>
                <div className="col-md-5">
                  <p>Mobile No. :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.mobileNo}</p>
                </div>
                <div className="col-md-5">
                  <p>Company Name :</p>
                </div>
                <div className="col-md-7">
                  <p>{viewData?.company?.name}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Buttons
              className="me-3"
              onClick={handleCloseview}
              content="Close"
            />
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <DeleteConfirmation
          showModal={deleteModal}
          hideModal={handleCloseDelete}
          centered
          confirmModel={deleteUser}
        ></DeleteConfirmation>
      </div>
    </>
  );
};

export default Users;
