import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BiSolidLockAlt } from "react-icons/bi";
import Validation from "../../common/validation";
import { HiOutlineUser } from "react-icons/hi2";
import CommonApiServices from "../../common/apiServices";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuth } from "../../redux/slice/loginSlice";
import * as Constants from "../../common/constant";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetpassword, setResetPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { state } = useLocation();
  const isFirstLogin = state;
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const passPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  /*-------------------- API CALL START HERE --------------------*/
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({ email });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      let payload = {
        email: email,
        password: resetpassword,
      };
      if (isFirstLogin?.isFirstLogin === "true") {
        payload["isFirstLogin"] = true;
      }

      try {
        const resJson = await CommonApiServices.postData(
          Constants.ChangePassword,
          payload
        );
        // clearData();
        if (resJson?.status === Constants.CODE_SUCCESS) {
          dispatch(setAuth(resJson?.data));
          navigate("/");
        } else {
          setEmail("");
          setResetPassword("");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  /*-------------------- API CALL END HERE --------------------*/
  return (
    <>
      <div className=" ">
        <h4>Change Password</h4>
      </div>
      <form className="log-form mt-3 w-50" onSubmit={onSubmitForm}>
        <div className="mb-4 position-relative">
          <label htmlFor="exampleInputEmail1" className="form-label">
            email id
          </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            id="exampleInputEmail1"
          />
          <HiOutlineUser
            style={{ color: " #051D34" }}
            className="img-fluid login-icon"
          />
          {errors.email && !pattern?.test(email) ? (
            <span className="error">{errors.email}</span>
          ) : null}
        </div>

        <div className="mb-4 position-relative">
          <label htmlFor="exampleInputPassword1" className="form-label">
            password
          </label>
          <input
            type="password"
            value={resetpassword}
            onChange={(e) => setResetPassword(e.target.value)}
            className="form-control ps-5 boxshowd"
            id="exampleInputPassword1"
          />
          <BiSolidLockAlt
            style={{ color: " #051D34" }}
            className="img-fluid login-icon"
          />
          {/* {errors?.resetpassword && !passPattern?.test(resetpassword) ? (
            <span className="error">{errors?.resetpassword}</span>
          ) : null} */}
          {errors?.resetpassword ? (
            <span className="error">{errors?.resetpassword}</span>
          ) : null}
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-secess w-25 mt-3 ">
            submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
