import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BiSolidLockAlt } from "react-icons/bi";
import Validation from "../../common/validation";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import swal from "sweetalert";
import store from "../../redux/store/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/loginSlice";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [resetpassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const loginUserDetail = store.getState().login.auth;

  const passPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = Validation({
      // password,
      resetpassword,
      confirmPassword,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const payload = {
          oldPassword: password,
          password: resetpassword,
        };
        const response = await CommonApiServices.putData(
          Constants.User + `/${loginUserDetail?.userId}/update/password`,
          payload
        );
        swal(`${response.message}`, "", "success");

        dispatch(logout());
        navigate("/login");

        return response;
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  return (
    <>
      <div className=" ">
        <h4>Update Password</h4>
      </div>
      <form className="log-form mt-3 w-50" onSubmit={onSubmitForm}>
        <div className="mb-4 position-relative">
          <label htmlFor="exampleInputPassword1" className="form-label">
            current password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control ps-5 boxshowd"
            id="exampleInputPassword1"
          />
          <BiSolidLockAlt
            style={{ color: " #051D34" }}
            className="img-fluid login-icon"
          />
          {errors?.password && !password ? (
            <span className="error">{errors?.password}</span>
          ) : null}
        </div>
        <div className="mb-4 position-relative">
          <label htmlFor="exampleInputPassword1" className="form-label">
            new password
          </label>
          <input
            type="password"
            value={resetpassword}
            onChange={(e) => setResetPassword(e.target.value)}
            className="form-control ps-5 boxshowd"
            id="exampleInputPassword1"
          />
          <BiSolidLockAlt
            style={{ color: " #051D34" }}
            className="img-fluid login-icon"
          />
          {errors?.resetpassword ? (
            <span className="error">{errors?.resetpassword}</span>
          ) : null}
          {/* {errors?.resetpassword && !passPattern?.test(resetpassword) ? (
            <span className="error">{errors?.resetpassword}</span>
          ) : null} */}
        </div>
        <div className="mb-4 position-relative">
          <label htmlFor="exampleInputPassword1" className="form-label">
            confirm Password
          </label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control ps-5 boxshowd"
            id="exampleInputPassword1"
          />
          <BiSolidLockAlt
            style={{ color: " #051D34" }}
            className="img-fluid login-icon"
          />
          {errors?.confirmPassword ? (
            <span className="error">{errors?.confirmPassword}</span>
          ) : null}
        </div>
        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-secess w-25 mt-3">
            submit
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdatePassword;
