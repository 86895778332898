const Validation = (props) => {
  const errors = {};
  // const passPattern =
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const passPattern = /^.{8,}$/;
  const emailPattern =
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  const numberPattern = /^[0-9]{10}$/;
  const spaceInput = /^\s*$/;

  if (props.email === "" || props.email === null) {
    errors.email = "Enter Email Address";
  } else if (!emailPattern?.test(props.email) && props.email) {
    errors.email = "Please Enter valid Email [eg. mailto:xxx@xxx.xxx]";
  }
  if (props.password === "" || props.password === null) {
    errors.password = "Enter Password";
  }
  if (props.resetpassword === "" || props.resetpassword === null) {
    errors.resetpassword = "Enter Password";
  } else if (!passPattern?.test(props.resetpassword) && props.resetpassword) {
    errors.resetpassword = "Password must Contain 8 Characters";
  }
  if (props.confirmPassword === "" || props.confirmPassword === null) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (props.confirmPassword !== props.resetpassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  if (spaceInput?.test(props.name) || props.name === null) {
    errors.name = "Enter name";
  }
  if (spaceInput?.test(props.firstName) || props.firstName === null) {
    errors.firstName = "Enter firstName";
  }
  if (spaceInput?.test(props.lastName) || props.lastName === null) {
    errors.lastName = "Enter lastName";
  }
  if (spaceInput?.test(props.name) || props.name === null) {
    errors.name = "Enter name";
  }
  if (spaceInput?.test(props.uniqueCode) || props.uniqueCode === null) {
    errors.uniqueCode = "Enter uniqueCode";
  }
  if (spaceInput?.test(props.description) || props.description === null) {
    errors.description = "Enter description";
  }
  if (props.mobileNo === "" || props.mobileNo === null) {;
    errors.mobileNo = "Enter mobileNo";
  } 
  // else if (!numberPattern?.test(props.mobileNo) && props.mobileNo) {
  //   errors.mobileNo = "Please enter valid phone number";
  // }
  if (props.mobileno === "" || props.mobileno === null) {
    errors.mobileno = "Enter mobileNo";
  }
  // else if (!numberPattern?.test(props.mobileno) && props.mobileno) {
  //   errors.mobileno = "Please enter valid phone number";
  // }
  if (spaceInput?.test(props.status) || props.status === null) {
    errors.status = "Enter status";
  }
  if (spaceInput?.test(props.templatename) || props.templatename === null) {
    errors.templatename = "Enter templatename";
  }
  if (spaceInput?.test(props.header) || props.header === null) {
    errors.header = "Enter templatename";
  }
  if (spaceInput?.test(props.body) || props.body === null) {
    errors.body = "Enter body";
  }
  if (spaceInput?.test(props.footer) || props.footer === null) {
    errors.footer = "Enter footer";
  }
  if (spaceInput?.test(props.category) || props.category === null) {
    errors.category = "Enter category";
  }
  if (spaceInput?.test(props.language) || props.language === null) {
    errors.language = "Enter language";
  }
  if (spaceInput?.test(props.location) || props.location === null) {
    errors.location = "Enter location";
  }
  if (spaceInput?.test(props.address) || props.address === null) {
    errors.address = "Enter address";
  }
  if (
    spaceInput?.test(props.productRetailerId) ||
    props.productRetailerId === null
  ) {
    errors.productRetailerId = "Enter Product Retailer Id";
  }
  if (spaceInput?.test(props.title) || props.title === null) {
    errors.title = "Enter title";
  }
  if (spaceInput?.test(props.availability) || props.availability === null) {
    errors.availability = "Enter availability";
  }
  if (spaceInput?.test(props.price) || props.price === null) {
    errors.price = "Enter price";
  }
  if (spaceInput?.test(props.condition) || props.condition === null) {
    errors.condition = "Enter condition";
  }
  if (spaceInput?.test(props.link) || props.link === null) {
    errors.link = "Enter link";
  }
  if (spaceInput?.test(props.imageLink) || props.imageLink === null) {
    errors.imageLink = "Select Image ";
  }
  if (spaceInput?.test(props.imageLinks) || props.imageLinks === null) {
    errors.imageLinks = "Select Image ";
  }

  if (
    spaceInput?.test(props.metaWAAccessToken) ||
    props.metaWAAccessToken === null
  ) {
    errors.metaWAAccessToken = "Enter metaWAAccessToken";
  }
  if (
    spaceInput?.test(props.metaWASenderPhoneNumberId) ||
    props.metaWASenderPhoneNumberId === null
  ) {
    errors.metaWASenderPhoneNumberId = "Enter metaWASenderPhoneNumberId";
  }
  if (spaceInput?.test(props.metaWAWabaId) || props.metaWAWabaId === null) {
    errors.metaWAWabaId = "Enter metaWAWabaId";
  }
  if (
    spaceInput?.test(props.metaWAVerifyToken) ||
    props.metaWAVerifyToken === null
  ) {
    errors.metaWAVerifyToken = "Enter metaWAVerifyToken";
  }
  if (spaceInput?.test(props.sendheader) || props.sendheader === null) {
    errors.sendheader = "Enter Header";
  }
  if (spaceInput?.test(props.sendbody) || props.sendbody === null) {
    errors.sendbody = "Enter body";
  }
  if (spaceInput?.test(props.sendfooter) || props.sendfooter === null) {
    errors.sendfooter = "Enter footer";
  }
  if (spaceInput?.test(props.categoryname) || props.categoryname === null) {
    errors.categoryname = "Enter category";
  }
  if (spaceInput?.test(props.product) || props.product === null) {
    errors.product = "Enter product";
  }
  if (spaceInput?.test(props.productimg) || props.productimg === null) {
    errors.productimg = "Select productImg";
  }
  if (spaceInput?.test(props.contactgroup) || props.contactgroup === null) {
    errors.contactgroup = "Select only one of contactgroup, allContacts, or contacts";
  }

  return errors;
};

export default Validation;
