import "./dashboard.css";
// import { Col,  Layout, Row, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import store from "../../redux/store/store";
import { FaCheck, FaTimes } from "react-icons/fa";
import CommonApiServices from "../../common/apiServices";
import * as Constants from "../../common/constant";
import React, { useEffect, useState } from "react";

import CommonTable from "../../components/table/commonTable";

const Dashboard = () => {
  const loginUserDetail = store.getState().login.auth;
  const isSuperAdmin = loginUserDetail?.isSuperAdmin;
  const companyId = loginUserDetail?.companyId;
  const [sendMessages, setSendMessages] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [active, setActive] = useState(0);
  const [inactive, setInactive] = useState(0);
  const [total, setTotal] = useState(0);
  const series2 = [active, inactive];
  const [loadingContact, setLoadingContact] = useState(true);
  const [loadingContactCount, setLoadingContactCount] = useState(true);
  const [loadingsuper, setLoadingSuper] = useState(true);
  const [loadingSuperCount, setLoadingSuperCount] = useState(true);

  useEffect(() => {
    if (isSuperAdmin) {
      getCompaniesList();
      getCompaniesCount();
    } else {
      getContactCount();
      getSendMessageList();
    }
  }, []);


    /*-------------------- API CALL START HERE --------------------*/
  const getCompaniesList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Company + `?page=${1}&pageSize=${10}`
      );
      setLoadingSuper(false)
      setCompanies(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getCompaniesCount = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Company + `/count`
      );
      setLoadingSuperCount(false)
      setActive(response.data.active);
      setInactive(response.data.inActive);
      setTotal(response.data.active + response.data.inActive);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getSendMessageList = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.SendMessage + `/company/${companyId}?page=${1}&pageSize=${10}`
      );
      setLoadingContact(false)
      setSendMessages(response?.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getContactCount = async () => {
    try {
      const response = await CommonApiServices.getData(
        Constants.Contact + `/company/${companyId}/count`
      );
      setActive(response.data.active);
      setLoadingContactCount(false)
      setInactive(response.data.inActive);
      setTotal(response.data.active + response.data.inActive);
    } catch (error) {
      console.error(error.message);
    }
  };
    /*-------------------- API CALL END HERE --------------------*/

  let columns = [];
  if (isSuperAdmin) {
    columns = [
      {
        name: "No",
        selector: (row) => row.companyId,
      },
      {
        name: "Unique code",
        selector: (row) => {
          return row?.uniqueCode ? row?.uniqueCode : "-";
        },
      },
      {
        name: "Name",
        selector: (row) => {
          return row?.name ? row?.name : "-";
        },
      },
      {
        name: "Description",
        selector: (row) => (row?.description ? row?.description : "-"),
      },
      {
        name: "Email",
        selector: (row) => (row?.email ? row?.email : "-"),
      },
      {
        name: "Mobile No",
        selector: (row) => (row?.mobileNo ? row?.mobileNo : "-"),
      },
    ];
  } else {
    columns = [
      {
        name: "No",
        selector: (row) => row.sendMessageId,
      },
      {
        name: "Template Name",
        selector: (row) => (row.templateName ? row.templateName : "-"),
      },
      {
        name: "Description",
        selector: (row) => (row.description ? row.description : "-"),
      },
      {
        name: "Is Template",
        selector: (row) => (row.isTemplate ? <FaCheck /> : <FaTimes />),
      },
      {
        name: "Message Text",
        selector: (row) =>
          row.messageDescription ? row.messageDescription : "-",
      },
      {
        name: "Total Contact",
        selector: (row) => (row.totalContact ? row.totalContact : "-"),
      },
      {
        name: "Sent",
        selector: (row) => (row.sentCount ? row.sentCount : "0"),
      },
      {
        name: "Delivered",
        selector: (row) => (row.deliveredCount ? row.deliveredCount : "0"),
      },
      {
        name: "Read",
        selector: (row) => (row.readCount ? row.readCount : "0"),
      },
    ];
  }

  const options2 = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: ["Active", "Inactive"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <>
      <div className="">
        <Container className="container p-0 d-flex justify-content-between customers_header">
          <h4> Dashboard </h4>
        </Container>
        {isSuperAdmin ? (
          <Container className="container p-0">
            <Row gutter={10}>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Total Company</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingSuperCount ? <Spinner /> :total}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Active Company</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingSuperCount ? <Spinner /> :active}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Inactive Company</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingSuperCount ? <Spinner /> :inactive}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={6}>
                <div className="ContentWrap3 p-3">
                  <Col span={24}>
                    <div className="d-flex  justify-content-between ">
                      <h6 className="fontbold">Company Count</h6>
                    </div>
                  </Col>

                  <ReactApexChart
                    options={options2}
                    series={series2}
                    type="donut"
                    width={380}
                    style={{ padding: "50px" }}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="ContentWrap2 p-3">
                  <Row>
                    <Col span={24}>
                      <h6 className="fontbold"> Companies </h6>
                    </Col>
                  </Row>
                  <Row>
                    <div className="table-responsive">
                      <CommonTable
                        columns={columns}
                        progressPending={loadingsuper}
                        data={companies}
                        defaultSortField="companyId"
                        defaultSortAsc={true}
                      />
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="container p-0">
            <Row gutter={10}>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Total Contact</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingContactCount ? <Spinner /> :total}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Active Contact</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingContactCount ? <Spinner /> :active}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card mb-3 min-h">
                  <div className="card-body task-box1">
                    <h5>Inactive Contact</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <Form.Text className="mb-0 text-white">
                        <b className="d-flex justify-content-center fontSize">
                        {loadingContactCount ? <Spinner /> :inactive}
                        </b>
                      </Form.Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={6}>
                <div className="ContentWrap3 p-3">
                  <Col span={24}>
                    <div className="d-flex  justify-content-between ">
                      <h6 className="fontbold">Contact Count</h6>
                    </div>
                  </Col>

                  <ReactApexChart
                    options={options2}
                    series={series2}
                    type="donut"
                    width={380}
                    style={{ padding: "50px" }}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="ContentWrap2 p-3">
                  <Row>
                    <Col span={24}>
                      <h6 className="fontbold"> Messages </h6>
                    </Col>
                  </Row>
                  <Row>
                    <div className="table-responsive table-scroll">
                      <CommonTable
                        columns={columns}
                        data={sendMessages}
                        progressPending={loadingContact}
                        defaultSortField="sendMessageId"
                        defaultSortAsc={true}
                      />
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};
export default Dashboard;
