import React, { useState } from "react";
import { BiSolidLockAlt } from "react-icons/bi";
import Validation from "../../common/validation";
import * as Constants from "../../common/constant";
import CommonApiServices from "../../common/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/slice/loginSlice";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [resetpassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { state } = useLocation();

  const navigate = useNavigate();
  let { email } = state;

  const passPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const onSubmitForm = async (e) => {
    e.preventDefault();
    // const validationErrors = Validation({ resetpassword, confirmPassword });
    // setErrors(validationErrors);
    // if (Object.keys(validationErrors).length === 0) {
    let payload = {
      email: email,
      password: resetpassword,
    };

    try {
      const resJson = await CommonApiServices.postData(
        Constants.ChangePassword,
        payload
      );
      // clearData();
      if (resJson?.status === Constants.CODE_SUCCESS) {
        dispatch(setAuth(resJson?.data));
        navigate("/login");
      } else {
        setResetPassword("");
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <div className="login-details text-center p-5">
            <div>
              <h1>
                whatsapp
                <br /> database
              </h1>
              <img
                src="/assets/img/login.jpg"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="login-form-box p-5">
            <div className="log-form-box p-5">
              <div className="login-form w-100">
                <h2>Reset Password</h2>
                {/* <p>user</p> */}
                <form className="log-form mt-3" onSubmit={onSubmitForm}>
                  <div className="mb-4 position-relative">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      password
                    </label>
                    <input
                      type="password"
                      value={resetpassword}
                      onChange={(e) => setResetPassword(e.target.value)}
                      className="form-control ps-5 boxshowd"
                      id="exampleInputPassword1"
                    />
                    <BiSolidLockAlt
                      style={{ color: " #051D34" }}
                      className="img-fluid login-icon"
                    />
                    {/* {errors?.resetpassword &&
                    !passPattern?.test(resetpassword) ? (
                      <span className="error">{errors?.resetpassword}</span>
                    ) : null} */}
                    {errors?.resetpassword ? (
                      <span className="error">{errors?.resetpassword}</span>
                    ) : null}
                  </div>
                  <div className="mb-4 position-relative">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      confirm Password
                    </label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control ps-5 boxshowd"
                      id="exampleInputPassword1"
                    />
                    <BiSolidLockAlt
                      style={{ color: " #051D34" }}
                      className="img-fluid login-icon"
                    />
                    {errors?.confirmPassword ? (
                      <span className="error">{errors?.confirmPassword}</span>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-3 btn-primary "
                  >
                    submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
