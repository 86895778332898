import { Outlet, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import Login from "./pages/login/login";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import VerificationCode from "./pages/forgotPassword/verificationCode";
import ResetPassword from "./pages/forgotPassword/resetPassword";
import { useState } from "react";
import Sidebar from "./components/Sidebar/sidebar";
import CompanyList from "./pages/companylist/companyList";
import "./common/common.css";
import MessageTemplate from "./pages/messageTemplate/messageTemplate";
import SendMessage from "./pages/messageTemplate/sendMessage";
import Contactus from "./pages/contactus/contactus";
import ContactGroup from "./pages/contactus/contactGroup";
import ProductTemplate from "./pages/template/templateProduct";
import Dashboard from "./pages/dashboard/dashboards";
import ChangePassword from "./pages/changePassword/changePassword";
import UpdatePassword from "./pages/updatePassword/updatePassword";
import CompanyEnv from "./pages/companyEnv/companyEnv";
import Users from "./pages/user/user";
import NoMatchRouter from "./pages/noMatchRouter/noMatchRouter";
import { PublicRoute } from "./Routers/publicRoute";
import { PrivateRoute } from "./Routers/privateRoute";
import ImageGallery from "./pages/imageGallery/imageGallery";
import SendMessageDetails from "./pages/messageTemplate/sendmessageDetails";
import Order from "./pages/order/order";
import OrderDetails from "./pages/order/orderDetails";

const AppOutLet = () => {
  const [toggel, Settoggle] = useState(false);
  return (
    <>
      <Sidebar toggel={toggel} Settoggle={Settoggle} />
      <div
        className="main-content p-3"
        style={
          !toggel
            ? { left: "78px", width: "calc(100% - 78px)" }
            : { left: "315px", width: "calc(100% - 315px)" }
        }
      >
        <Outlet />
      </div>
    </>
  );
};
function App() {
  const user = "superAdmin";
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AppOutLet />
            </PrivateRoute>
          }
        >
          {/* <Route path="/" element={<AppOutLet />}> */}
          <Route exact path="/" element={<Dashboard />} />
          {user && (
            <>
              <Route exact path="/users" element={<Users />} />
              <Route exact path="/companylist" element={<CompanyList />} />
            </>
          )}
          <Route exact path="/companyenv" element={<CompanyEnv />} />
          <Route exact path="/messagetemplate" element={<MessageTemplate />} />
          <Route exact path="/imagegallery" element={<ImageGallery />} />
          <Route exact path="/sendmessage" element={<SendMessage />} />
          <Route exact path="/order" element={<Order />} />
          <Route
            exact
            path="/order/orderdetails/:id"
            element={<OrderDetails />}
          />
          <Route
            exact
            path="/sendmessage/sendMessageDetails/:id"
            element={<SendMessageDetails />}
          />
          <Route exact path="/contact" element={<Contactus />} />
          <Route exact path="/contactgroup" element={<ContactGroup />} />
   
          <Route
            exact
            path="/messagetemplate/producttemplate/:id"
            element={<ProductTemplate />}
          />
          <Route exact path="/changepassword" element={<ChangePassword />} />
          <Route exact path="/updatepassword" element={<UpdatePassword />} />
        </Route>
        <Route
          exact
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/verificationcode"
          element={
            <PublicRoute>
              <VerificationCode />
            </PublicRoute>
          }
        />{" "}
        <Route
          exact
          path="/resetpassword"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />{" "}
        <Route
          exact
          path="/forgotpassword"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route path="*" element={<NoMatchRouter />} />
        {/* <Route exact path="/login" element={<Login />} />
        <Route exact path="/verificationcode" element={<VerificationCode />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} /> */}
        {/* <Route path="*" element={<NoMatchRouter />} /> */}
      </Routes>
    </>
  );
}

export default App;
